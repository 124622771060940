import React from "react";
import { Outlet } from "react-router-dom";
import OfficeSideBar from "./OfficeSideBar";

export default function OfficeLayout() {
  return (
    <div className="flex h-screen">
      <OfficeSideBar />
      <main className="flex-1 px-4 py-6 sm:px-6">
        <Outlet />
      </main>
    </div>
  );
}
