import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OwnerAuthState {
  email: string | null;
}

const initialState: OwnerAuthState = {
  email: null,
};

const ownerUserSlice = createSlice({
  name: "ownerAuth",
  initialState,
  reducers: {
    setOwnerAuth: (state, action: PayloadAction<OwnerAuthState>) => {
      state.email = action.payload.email;
    },
  },
});
export const { setOwnerAuth } = ownerUserSlice.actions;
export default ownerUserSlice.reducer;
