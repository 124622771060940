import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import logo from "../../image/logo.png";
import { usePostUserLoginMutation } from "../../api/busLineApiData";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../api/getCredentials";
import { errorHandler } from "../../error/index";

interface LoginFormData {
  email: string;
  password: string;
}

interface LoginFormError {
  email?: string;
  password?: string;
}

interface JwtPayLoadData {
  email: string;
  exp: Date;
  iat: Date;
  phoneNumber: string;
  role: string;
  userId: string;
  userName: string;
}

export default function UserLogin() {
  const [login, { isLoading }] = usePostUserLoginMutation();
  const [loginData, setLoginData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<LoginFormError>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showError, setShowError] = useState(false);

  const validateForm = () => {
    let newErrors: LoginFormError = {};

    if (!loginData.email.trim()) newErrors.email = "Email is required";
    if (!loginData.password.trim()) newErrors.password = "Password is required";
    else if (loginData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const navigate = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const getErrorMessage = (
    error: FetchBaseQueryError | SerializedError | undefined
  ): string => {
    if (!error) {
      return "An unknown error occurred";
    }

    if ("status" in error) {
      // FetchBaseQueryError
      if (error.status === "PARSING_ERROR") {
        return `Server Error: ${error.originalStatus}`;
      }
      return "error" in error ? error.error : JSON.stringify(error.data);
    } else {
      // SerializedError
      return error.message ?? "An unknown error occurred";
    }
  };

  useEffect(() => {
    if (showError) {
      const timer = setTimeout(() => {
        setShowError(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showError]);
  const dismissError = () => {
    setShowError(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      console.log(loginData);
      const result = await login(loginData);
      if ("data" in result) {
        const companyData = {
          token: result.data.token,
          companyId: result.data.userAndCompanyData.companyId,
        };
        console.log(result);
        localStorage.setItem("companyToken", JSON.stringify(companyData));
        const decoded = jwtDecode<JwtPayLoadData>(result.data.token)
        console.log(decoded.role);
        if (decoded.role === "COMPANY_ADMIN") {
          navigate("/biz/home", { replace: true });
        } else if (decoded.role === "DRIVER") {
          errorHandler({ message: "Drivers do not login from here." });
        } else if (decoded.role === "CUSTOMER") {
          errorHandler({ message: "Customers do not login from here." });
        } else if (decoded.role === "PRIVATE_DRIVER") {
          errorHandler({ message: "Private drivers feature yet to come." });
        }
        console.log("Login successful", result);
        loginData.email = "";
        loginData.password = "";
      } else {
        return errorHandler(result.error);
      }
    }
  };
  return (
    <>
      <form onSubmit={handleLogin}>
        <div className="flex flex-col items-center justify-center min-h-screen bg-customLightBlue px-4 py-8">
          <div className="mb-8">
            <img src={logo} alt="Logo" className="h-24 w-38" />
          </div>
          <div className="w-full max-w-lg p-6 md:p-8 space-y-6 bg-customLightBlue rounded-md shadow-md">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              Sign In
            </h2>

            <div>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                value={loginData.email}
              />
              {errors.email && (
                <span className="text-red-500 text-sm">{errors.email}</span>
              )}
            </div>
            <div>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                placeholder="Password"
                onChange={handleChange}
                value={loginData.password}
                name="password"
              />
              {errors.password && (
                <span className="text-red-500 text-sm">{errors.password}</span>
              )}
            </div>
            <button
              onClick={() => navigate("/sign-up", { replace: true })}
              className="text-blue-600"
            >
              Go back
            </button>
            <Button
              className="w-full  text-white bg-customDarkBlue"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading.." : "Login"}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
