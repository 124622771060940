import React, { useState,} from "react";
import { Button } from "../../components/ui/button";

import OwnerAndVehicle from "./OwnerAndVehicle";

import CompanyFleetTableData from "./CompanyFleet Table/company_fleet_page";

const FleetOwners: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  return (
    <>
      <Button
        className="bg-customDarkBlue text-white mx-auto mt-4 block"
        onClick={openDialog}
      >
        Add Owner & vehicle
      </Button>
      <CompanyFleetTableData />
      <OwnerAndVehicle isOpen={isDialogOpen} onClose={closeDialog} />
    </>
  );
};
export default FleetOwners;
