import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../slices/types";
import { BASE_URL, LOCAL_BASEURL } from "../constants/constants";

export const busLineOfficeApi = createApi({
  reducerPath: "busLineOfficeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // baseUrl: LOCAL_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const { token,companyId } = state.companyAuth;
      if (token) {
        headers.set("token", token);
      }
      headers.set("Content-Type", "application/json");
      headers.set("companyId", companyId || ""); // Set companyId in headers
      return headers;
    },
  }),
  tagTypes: ['OfficeData'],
  endpoints: (builder) => ({
    postOfficeLogin: builder.mutation({
      query: ({ officeLoginData }) => ({
        url: "/auth/officeAdminLogin",
        method: "POST",
        body: officeLoginData,
      }),
    }),
    getCompanyFleetFromOffice: builder.query<any, string>({
      query: (companyId) => ({
        url: `/get-company-fleet?companyId=${companyId}`,
        method: "GET",
      }),
    }),
    getOfficeRevenueAndData: builder.query<any, any>({
      query: ({officeId, page,formattedDate}) => ({
        url: `/get-office-revenue-and-data?officeId=${officeId}&page=${page}&date=${formattedDate}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => 
        result
          ? [{ type: 'OfficeData', id: arg.officeId }, 'OfficeData']
          : ['OfficeData'],
    }),
    postAddParcelOffice: builder.mutation({
      query: ({ parcelDataOffice, companyId, officeId, vehicleId }) => ({
        url: `/create-parcel`,
        method: "POST",
        body: {
          senderName: parcelDataOffice.senderName,
          senderPhone: parcelDataOffice.senderPhoneNumber,
          recipientName: parcelDataOffice.recipientName,
          recipientPhone: parcelDataOffice.recipientPhone,
          fromLocation: parcelDataOffice.from,
          toLocation: parcelDataOffice.to,
          fees: parcelDataOffice.charges,
          companyId,
          officeId,
          vehicleId,
        },
      }),
    }),
    postTicketData: builder.mutation({
      query: ({ ticketData, vehicleRegistrationNumber, officeName }) => ({
        url: "/create-manual-ticket",
        method: "POST",
        body: {
          passengerName: ticketData.passengerName,
          officeName: officeName,
          phoneNumber: ticketData.phoneNumber,
          fromDestination: ticketData.from,
          toDestination: ticketData.to,
          fare: parseFloat(ticketData.fare),
          vehicleRegistrationNumber: vehicleRegistrationNumber,
        },
        invalidatesTags: ['OfficeData'],
      }),
    }),
    getOfficeParcelData: builder.query<any, string>({
      query: (officeId) => ({
        url: `/get-All-Office-Parcels?officeId=${officeId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostOfficeLoginMutation,
  useGetCompanyFleetFromOfficeQuery,
  useGetOfficeRevenueAndDataQuery,
  usePostAddParcelOfficeMutation,
  useGetOfficeParcelDataQuery,
  usePostTicketDataMutation
} = busLineOfficeApi;
export const { getCompanyFleetFromOffice } = busLineOfficeApi.endpoints;
