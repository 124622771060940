import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CompanyAuthState {
  token: string | null;
  companyId: string | null;
  officeName: string | null;
  officeId: string | null;
  role: string | null;
}

const initialState: CompanyAuthState = {
    token: null,
    companyId: null,
    officeId: null,
    officeName: null,
    role:null
};

const companyUserSlice = createSlice({
  name: "companyAuth",
  initialState,
  reducers: {
    setCompanyAuth: (state, action: PayloadAction<CompanyAuthState>) => {
          state.token = action.payload.token;
          state.companyId = action.payload.companyId;
          state.officeId = action.payload.officeId;
          state.officeName = action.payload.officeName;
          state.role = action.payload.role
    },
  },
});

export const { setCompanyAuth } = companyUserSlice.actions;
export default companyUserSlice.reducer;
