import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../components/ui/table";
import { DatePickerDemo } from "../../components/ui/DatePickerDemo";
import {
  useGetDriverVehicleIdQuery,
  useGetDriverIncomeQuery,
} from "../../api/busLineUserApi";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useNormalUserAuth } from "../../api/NormalUserAuth";
import { errorHandler } from "../../error/index";
import { Loader2 } from "lucide-react";
import { Badge } from "../../components/ui/badge";
import { useGetSpecificDriverReportsQuery } from "../../api/busLineUserApi";

import { Button } from "../../components/ui/button";
import ResetModal from "./ResetModal";
import { dateFormatDayMonthYear } from "../utility_components/utility_functions/utilityFunctions";

interface Ticket {
  id: string;
  ticketNumber: string;
  passengerName: string;
  fare: number;
  travelDate: string;
}

interface Trip {
  tickets: Ticket[];
}

interface DriverIncomeData {
  trips: Trip[];
  totalIncome: number;
  ticketCount: number;
  vehicle: {
    registrationNumber: string;
  };
}

interface DriverReportsData {
  totalExpenses: number;
}

export default function DriverPage(): JSX.Element {
  const { email, userId } = useNormalUserAuth();
  const [isResetOpen, setResetOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>();

  const formattedDate = formatDate(date);

  const {
    data: vehicleData,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
    error: vehicleError,
  } = useGetDriverVehicleIdQuery(email ?? skipToken);
  const {
    data: driverIncomeData,
    error: incomeError,
    isLoading: isIncomeLoading,
    isError: isIncomeError,
    refetch,
  } = useGetDriverIncomeQuery(
    { vehicleId: vehicleData?.vehicleId, dateString: formattedDate },
    { skip: !vehicleData?.vehicleId }
  );
  const {
    data: driverReportsData,
    error: reportsError,
    isError: isReportsError,
  } = useGetSpecificDriverReportsQuery({ userId, formattedDate });

  const handleDateChange = useCallback((newDate: Date | undefined) => {
    setDate(newDate);
  }, []);

  useEffect(() => {
    if (formattedDate && vehicleData?.vehicleId) {
      refetch();
    }
  }, [formattedDate, vehicleData?.vehicleId, refetch]);

  useEffect(() => {
    if (isVehicleError && vehicleError) errorHandler(vehicleError);
    if (isReportsError && reportsError) errorHandler(reportsError);
    if (isIncomeError && incomeError) errorHandler(incomeError);
  }, [
    isVehicleError,
    isIncomeError,
    isReportsError,
    vehicleError,
    incomeError,
    reportsError,
  ]);

  if (isVehicleLoading || isIncomeLoading) {
    return <LoadingSpinner />;
  }

  const totalIncome = calculateTotalIncome(driverIncomeData, driverReportsData);

  return (
    <>
      <div className="mb-3 overflow-x-auto">
        <Button
          className="bg-customDarkBlue text-white mx-auto mt-4 block"
          onClick={() => setResetOpen(true)}
        >
          Reset Vehicle Status & Capacity
        </Button>
        <div className="mt-3">
          <DatePickerDemo date={date} setDate={handleDateChange} />
        </div>
      </div>

      {renderIncomeData(driverIncomeData, driverReportsData, totalIncome)}

      <div className="inline-block w-full align-middle">
        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
          <Table className="w-full min-w-[90%] md:min-w-[800px] lg:min-w-[1000px] divide-y divide-gray-200 bg-customLightBlue">
            <TableHeader>
              <TableRow>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ticket ID
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fare Revenue
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Vehicle Reg Number
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Travel Date
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="bg-white divide-y divide-gray-200">
              {renderTableRows(driverIncomeData)}
            </TableBody>
          </Table>
        </div>
      </div>

      {vehicleData?.vehicleId && (
        <ResetModal
          isOpen={isResetOpen}
          onClose={() => setResetOpen(false)}
          vehicleId={vehicleData.vehicleId}
        />
      )}
    </>
  );
}

function formatDate(date: Date | undefined): string {
  return date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`
    : "";
}

function LoadingSpinner(): JSX.Element {
  return (
    <div className="flex justify-center items-center h-screen">
      <Loader2 className="h-8 w-8 animate-spin" />
      <span className="ml-2">Loading...</span>
    </div>
  );
}

function calculateTotalIncome(
  driverIncomeData: DriverIncomeData | undefined,
  driverReportsData: DriverReportsData | undefined
): number {
  if (!driverIncomeData || !driverReportsData) return 0;

  const totalExpenses = driverReportsData.totalExpenses ?? 0;
  const totalIncomeValue = driverIncomeData.totalIncome ?? 0;

  return Math.max(0, totalIncomeValue - totalExpenses);
}

function renderIncomeData(
  driverIncomeData: DriverIncomeData | undefined,
  driverReportsData: DriverReportsData | undefined,
  totalIncome: number
): JSX.Element | null {
  if (
    !driverIncomeData ||
    !driverIncomeData.trips ||
    driverIncomeData.trips.length === 0
  )
    return null;

  return (
    <div
      className="flex justify-between gap-4"
      style={{
        backgroundColor: "#7485BF",
        color: "white",
        textAlign: "center",
        padding: "10px",
        fontSize: "30px",
      }}
    >
      <span>
        Total Income: Ksh {driverIncomeData.totalIncome?.toFixed(2) ?? "0.00"}
      </span>
      <span>Ticket Count: {driverIncomeData.ticketCount ?? "0"}</span>
      <span>
        Driver Expenditure:{" "}
        {driverReportsData?.totalExpenses?.toFixed(2) ?? "0.00"}
      </span>
      <span>Driver Revenue: {totalIncome.toFixed(2)}</span>
    </div>
  );
}

function renderTableRows(
  driverIncomeData: DriverIncomeData | undefined
): JSX.Element {
  if (!driverIncomeData?.trips || driverIncomeData.trips.length === 0) {
    return (
      <TableRow>
        <TableCell
          colSpan={6}
          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
        >
          No income reports found
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {driverIncomeData.trips
        .flatMap((trip) => trip.tickets)
        .reverse()
        .map((ticket) => (
          <TableRow key={ticket.id} className="hover:bg-gray-50">
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {ticket.ticketNumber}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {ticket.passengerName}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {ticket.fare}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {driverIncomeData.vehicle.registrationNumber}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {dateFormatDayMonthYear(ticket.travelDate)}
            </TableCell>
            <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <Badge variant="outline">PAID</Badge>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
}
