import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import {
  OfficeData,
  OfficeDataError,
} from "../utility_components/utility_functions/utilityFunctions";
import { usePostCompanyAddOfficeMutation } from "../../api/busLineApiData";
import { useAuth } from "../../api/getCredentials";
import { setLoading } from "../../slices/loader";

import { useDispatch } from "react-redux";
import { clearError } from "../../slices/errorSlice";
import { useGetCompanyOfficesQuery } from "../../api/busLineApiData";
import { errorHandler } from "../../error/index";
import { successFunctionDisplay } from "../../success/success";

export default function AddBookingOffice({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const [officeData, setOfficeData] = useState<OfficeData>({
    name: "",
    address: "",
    city: "",
  });

  const [errors, setErrors] = useState<OfficeDataError>({});
  const [postCompanyOffice, { isLoading: addOfficeLoading }] =
    usePostCompanyAddOfficeMutation();
  const { token, companyId } = useAuth();
  const { refetch } = useGetCompanyOfficesQuery({companyId, page:1}, {
    skip: !token || !companyId,
  });

  const validateForm = () => {
    let newErrors: OfficeDataError = {};

    if (!officeData.name.trim()) newErrors.name = "Office name is required";
    if (!officeData.city.trim()) newErrors.city = "Office city is required";
    if (!officeData.address.trim())
      newErrors.address = "Office address is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setOfficeData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const result = await postCompanyOffice({
        officeData,
        companyId,
      });
      if ("data" in result) {
        console.log("Office Adding successful", result);
        officeData.address = "";
        officeData.name = "";
        officeData.city = "";
        dispatch(setLoading(addOfficeLoading));
        onClose();
        refetch();
        successFunctionDisplay("Successfully added office..");
      } else {
        onClose();
        return errorHandler(result.error);
      }
    }
  };
  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
  <DialogContent
    className="w-[95vw] sm:w-[90vw] md:max-w-[650px] h-[95vh] sm:h-auto max-h-[95vh] flex flex-col overflow-hidden"
    onClick={(e) => e.stopPropagation()}
  >
    <DialogHeader className="shrink-0">
      <DialogTitle className="text-xl sm:text-2xl">
        Add Booking Office Information
      </DialogTitle>
    </DialogHeader>
    <form onSubmit={handleSubmit} className="flex flex-col flex-grow overflow-hidden">
      <div className="flex-grow overflow-auto bg-customLightBlue p-4">
        <div className="space-y-4 min-w-[280px]">
          <div>
            <Label htmlFor="name" className="text-base sm:text-lg block mb-1">
              Office Name
            </Label>
            <Input
              id="name"
              placeholder="Office Name"
              className="w-full"
              name="name"
              value={officeData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <span className="text-red-500 text-xs sm:text-sm block mt-1">{errors.name}</span>
            )}
          </div>
          <div>
            <Label htmlFor="address" className="text-base sm:text-lg block mb-1">
              Office Address
            </Label>
            <Input
              id="address"
              placeholder="Office Address"
              className="w-full"
              name="address"
              value={officeData.address}
              onChange={handleChange}
            />
            {errors.address && (
              <span className="text-red-500 text-xs sm:text-sm block mt-1">{errors.address}</span>
            )}
          </div>
          <div>
            <Label htmlFor="city" className="text-base sm:text-lg block mb-1">
              City
            </Label>
            <Input
              id="city"
              placeholder="Office City"
              className="w-full"
              name="city"
              value={officeData.city}
              onChange={handleChange}
            />
            {errors.city && (
              <span className="text-red-500 text-xs sm:text-sm block mt-1">{errors.city}</span>
            )}
          </div>
        </div>
      </div>
      <DialogFooter className="mt-4 sm:mt-6 px-4 shrink-0">
        <Button
          type="submit"
          className="w-full bg-customDarkBlue text-white py-2 text-base sm:text-lg"
        >
          Done
        </Button>
      </DialogFooter>
    </form>
  </DialogContent>
</Dialog>

    </>
  );
}
