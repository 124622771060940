import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { formatTimestamp } from "../../utility_components/utility_functions/utilityFunctions";
export type CompanyTicketsData = {
  ticketNumber: string;
  ticketId: string;
  userName: string;
  userPhoneNumber: string;
  routeStartPoint: string;
  routeEndPoint: string;
  price: string;
  officeName: string;
  purchasedDate: string;
};

export const columns: ColumnDef<CompanyTicketsData>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected()
            ? true
            : table.getIsSomePageRowsSelected()
            ? "indeterminate"
            : false
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "ticketNumber",
    header: "Ticket Number",
  },
  {
    accessorKey: "userName",
    header: "User Name",
  },
  {
    accessorKey: "userPhoneNumber",
    header: "Phone Number",
  },
  {
    accessorKey: "routeStartPoint",
    header: "Start Point",
  },
  {
    accessorKey: "routeEndPoint",
    header: "End Point",
  },
  {
    accessorKey: "price",
    header: "Price",
    cell: ({ row }) => {
      const price = parseFloat(row.getValue("price"));
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "KES",
      }).format(price);
      return <div className="font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "officeName",
    header: "Office Name",
  },
  {
    accessorKey: "purchasedDate",
    header: "Purchased Date",
    cell: ({ row }) => {
      const date: string = row.getValue("purchasedDate");
      const formatted = formatTimestamp(date);
      return <div className="font-medium">{formatted}</div>;
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const companyTickets = row.original;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>View payment details</DropdownMenuItem>
            <DropdownMenuItem
              className="bg-red-500 text-white hover:bg-red-600"
              onClick={() =>
                navigator.clipboard.writeText(companyTickets.ticketId)
              }
            >
              Delete Payment Data
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
