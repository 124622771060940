import React, { useEffect, useState } from "react";
import { CompanyTicketsData, columns } from "./company_ticket_column";
import { DataTable } from "../Re-Usables/data-table";
import { useAuth } from "../../../api/getCredentials";
import { useGetSoldTicketsAndDataQuery } from "../../../api/busLineApiData";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";

export default function CompanyTicketsTable() {
  const { companyId, token } = useAuth();
  const [pageIndex, setPageIndex] = useState(0);
  const {
    isLoading: getSoldTicketsLoading,
    data: ticketData,
    error: getTicketsError,
    isError: isGetTicketError,
  } = useGetSoldTicketsAndDataQuery(
    { companyId, page: pageIndex + 1 },
    {
      skip: !token || !companyId,
    }
  );

  useEffect(() => {
    if (isGetTicketError) {
      errorHandler(getTicketsError);
    }
  }, [getTicketsError, isGetTicketError]);

  const prepareTableData = (): CompanyTicketsData[] => {
    if (
      !ticketData ||
      !ticketData.companyData ||
      !ticketData.companyData.offices
    ) {
      return [];
    }

    return ticketData.companyData.offices.flatMap((office) =>
      office.tickets.map((ticket) => ({
        ticketNumber: ticket.ticketNumber,
        userName: `${ticket.user?.firstName ?? ""} ${
          ticket.user?.lastName ?? ""
        }`,
        ticketId: ticket.id,
        userPhoneNumber: ticket.user?.phoneNumber ?? "N/A",
        routeStartPoint: ticket.route?.startPoint ?? "N/A",
        routeEndPoint: ticket.route?.endPoint ?? "N/A",
        price: ticket.price,
        officeName: office.name,
        purchasedDate: ticket.purchaseDate,
      }))
    );
  };

  if (getSoldTicketsLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();

  return (
    <div className="container mx-auto py-10">
      <div className="bg-[#7485BF] text-white text-center p-4 text-xl sm:text-2xl md:text-3xl font-semibold mb-6">
        {ticketData?.totalSells && ticketData?.totalTickets && (
          <>
            Total Page Sales: Ksh {ticketData.totalSells?.toFixed(2) ?? "0.00"}
            &nbsp;&nbsp;&nbsp; Total Page Tickets: {ticketData.totalTickets}
          </>
        )}
      </div>
      <DataTable
        columns={columns}
        data={tableData}
        pageCount={ticketData?.totalPages ?? 1}
        pageIndex={pageIndex}
        onPaginationChange={setPageIndex}
      />
    </div>
  );
}
