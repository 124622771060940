import React from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";

export default function PasswordDisplay({
  isOpen,
  onClose,
  message,
  password,
  note,
}) {
  return (
    <>
      <div className="bg-customLightBlue">
        <Dialog open={isOpen} onOpenChange={onClose}>
          <DialogContent
            className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
            onClick={(e) => e.stopPropagation()}
          >
            <DialogHeader>
              <DialogTitle className="text-2xl">
                Office Password Info
              </DialogTitle>
            </DialogHeader>
            <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue">
              <div>
                <p>Message: {message}</p>
                <strong>
                  {" "}
                  <p>Office Password: {password}</p>
                </strong>
                <p>Note: {note}</p>
              </div>
            </div>

            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-lg"
                onClick={() => onClose()}
              >
                Exit
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
