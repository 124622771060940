import React from "react";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";

interface OfficeData {
  name: string;
  tickets: number;
  ticketsAmount: number;
  parcelsAmount: number;
}

export default function TripsAndSharesBusiness() {
  const offices: OfficeData[] = [
    {
      name: "KAKAMEGA BOOKING OFFICE",
      tickets: 124,
      ticketsAmount: 103000000,
      parcelsAmount: 104000,
    },
    {
      name: "KISUMU BOOKING OFFICE",
      tickets: 124,
      ticketsAmount: 103000000,
      parcelsAmount: 104000,
    },
    {
      name: "NAIROBI BOOKING OFFICE",
      tickets: 124,
      ticketsAmount: 103000000,
      parcelsAmount: 104000,
    },
    {
      name: "NAIROBI BOOKING OFFICE",
      tickets: 124,
      ticketsAmount: 103000000,
      parcelsAmount: 104000,
    },
    {
      name: "NAIROBI BOOKING OFFICE",
      tickets: 124,
      ticketsAmount: 103000000,
      parcelsAmount: 104000,
    },
    {
      name: "NAIROBI BOOKING OFFICE",
      tickets: 124,
      ticketsAmount: 103000000,
      parcelsAmount: 104000,
    },
  ];
  const totalTickets = offices.reduce((sum, office) => sum + office.tickets, 0);
  const totalTicketsAmount = offices.reduce(
    (sum, office) => sum + office.ticketsAmount,
    0
  );

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {offices.map((office, index) => (
          <Card key={index} className="relative">
            <CardHeader>
              <CardTitle className="text-sm font-medium text-blue-600">
                <h1 className="text-lg font-bold">{office.name}</h1>
              </CardTitle>
            </CardHeader>

            <CardContent>
              <p className="text-sm mb-3">
                <span className="text-blue-600 font-bold text-lg">
                  Trips Made:
                </span>
                <span className="text-black text-lg">
                  {" "}
                  @ Ksh {office.tickets}
                </span>
              </p>

              <p>24</p>

              <p className="font-bold mt-2 mb-3 text-blue-600">
                Shares Collected
              </p>

              <p className="font-semibold mb-3">
                KSH {office.ticketsAmount.toLocaleString()}
              </p>
            </CardContent>
            <div className="absolute bottom-4 right-4">
              <Button variant="default" className="bg-customDarkBlue">
                View
              </Button>
            </div>
          </Card>
        ))}
      </div>

      <div className="flex-grow flex items-center justify-center">
        <Card className="bg-customDarkBlue text-white p-6 max-w-md w-full mx-auto relative">
          <CardHeader>
            <CardTitle className="text-center">TOTAL</CardTitle>
          </CardHeader>
          <CardContent>
            <p>
              <span className="text-black  text-xl font-bold">
                Trips Made:{" "}
              </span>
            </p>
            <p>
              <span className="text-white font-bold text-lg">
                {totalTickets.toLocaleString()}
              </span>
            </p>
            <p className="font-bold text-lg mt-3">
              <span className="text-black"> Shares Collected:</span>
            </p>
            <p className="font-bold">
              KSH {totalTicketsAmount.toLocaleString()}
            </p>
          </CardContent>
          <div className="absolute bottom-4 right-4">
            <Button variant="outline" className="bg-customDarkBlue">
              View
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
