import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export interface FleetFormData {
  vehicleRegistrationNumber: string;
  vehicleModel: string;
  vehicleCapacity: number;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  driverEmail: string;
}

export interface OwnerEmail {
  ownerEmail: string;
}

export interface OwnerEmailError {
  ownerEmail?: string;
}

export interface FleetFormErrors {
  vehicleRegistrationNumber?: string;
  vehicleModel?: string;
  vehicleCapacity?: string;
  ownerName?: string;
  ownerEmail?: string;
  ownerPhone?: string;
  driverEmail?: string;
}

export interface TicketData {
  passengerName: string;
  from: string;
  fare: number | string;
  to: string;
  vehicleRegistration: string;
  phoneNumber: string;
  officeName: string;
}
export interface VehicleRoute {
  routeName: string;
  startPoint: string;
  endPoint: string;
}

export interface BookingOfficeData{
  companyName: string;
  officeName: string;
  password: string;
}

export interface BookingOfficeDataError{
  companyName?: string;
  officeName?: string;
  password?: string;
}

export interface FareAmountRoute{
  fareAmount: string;
}

export interface VehicleCapacity{
  vehicleCapacity: string;
}
export interface VehicleCapacityError{
  vehicleCapacity?: string;
}

export interface FareAmountError{
  fareAmount?: string;
}

export interface VehicleRouteError {
  routeName?: string;
  startPoint?: string;
  endPoint?: string;
}

export interface TicketDataError {
  passengerName?: string;
  from?: string;
  fare?: number | string;
  to?: string;
  vehicleRegistration?: string;
  phoneNumber?: string;
  officeName?: string;
}

export interface OfficeData {
  name: string;
  address: string;
  city: string;
}

export interface OfficeDataError {
  name?: string;
  address?: string;
  city?: string;
}

export interface OwnerAndVehicleProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface ParcelForms {
  senderName: string;
  from: string;
  to: string;
  charges: number;
  senderPhoneNumber: string;
  recipientName: string;
  recipientPhone: string;
}

export interface ParcelFormsError {
  senderName?: string;
  from?: string;
  to?: string;
  charges?: string;
  senderPhoneNumber?: string;
  recipientName?: string;
  recipientPhone?: string;
}

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError | undefined
): string => {
  if (!error) {
    return "An unknown error occurred";
  }

  if ("status" in error) {
    // FetchBaseQueryError
    if (error.status === "PARSING_ERROR") {
      return `Server Error: ${error.originalStatus}`;
    }
    return "error" in error ? error.error : JSON.stringify(error.data);
  } else {
    // SerializedError
    return error.message ?? "An unknown error occurred";
  }
};

export const validateKenyanPhoneNumber=(phoneNumber: string): boolean=> {
  // Regular expression to check if the number starts with +254 or 07
  const regex = /^(?:\+254|07)\d+$/;

  if (!regex.test(phoneNumber)) {
    return false; // If it doesn't match the pattern, it's invalid
  }

  // Check length based on the prefix
   if (phoneNumber.startsWith("07")) {
    return phoneNumber.length === 10; // 07 followed by 8 digits
  }

  return false; // If neither condition is met, the number is invalid
}


export function formatTimestamp(timestamp: string): string {
  const date = new Date(timestamp);
  
  const formatter = new Intl.DateTimeFormat('en-KE', { // 'en-KE' for English (Kenya)
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
    timeZone: 'Africa/Nairobi' // East African Time
  });

  return formatter.format(date);
}