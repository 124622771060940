import React, { useCallback, useEffect } from "react";
import { useAuth } from "../../api/getCredentials";
import {
  useLazyGetCompanyAuditTotalIncomeQuery,
  useLazyGetCompanyAuditTotalExpenseQuery,
} from "../../api/busLineApiData";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../components/ui/table";
import { DatePickerIncomeDemo } from "../../components/ui/DatePickerIncomeDemo";
import { DatePickerIncomeDemoToDate } from "../../components/ui/DatePickerIncomeDemoToDate";
import { Loader2 } from "lucide-react";
import { errorHandler } from "../../error/index";

export default function AuditReport() {
  const { companyId } = useAuth();
  const [date, setDate] = React.useState<Date | undefined>();
  const [toDate, setToDate] = React.useState<Date | undefined>();
  const startDate = date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`
    : "";
  const endDate = toDate
    ? `${toDate.getFullYear()}-${String(toDate.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(toDate.getDate()).padStart(2, "0")}`
    : "";
  const handleDateChange = useCallback((newDate: Date | undefined) => {
    setDate(newDate);
    console.log("Date changed:", newDate);
  }, []);
  const handleToDateChange = useCallback((newDate: Date | undefined) => {
    setToDate(newDate);
    console.log("Date changed:", newDate);
  }, []);
  const [
    getIncomeAuditData,
    {
      isLoading: isIncomeLoading,
      isError: isIncomeError,
      error: incomeError,
      data: incomeData,
    },
  ] = useLazyGetCompanyAuditTotalIncomeQuery();
  const [
    getExpenseAudit,
    { isError: isExpenseError, error: expenseError, data: expenseData },
  ] = useLazyGetCompanyAuditTotalExpenseQuery();
  useEffect(() => {
    if (companyId && startDate && endDate) {
      console.log("Making the request:.....");
      getIncomeAuditData({ companyId, startDate, endDate });

      getExpenseAudit({ companyId, startDate, endDate });
    }
  }, [companyId, endDate, getExpenseAudit, getIncomeAuditData, startDate]);

  useEffect(() => {
    if (companyId && startDate && endDate) {
      console.log("Making the later req:.....");
      getExpenseAudit({ companyId, startDate, endDate });
    }
  }, [companyId, endDate, getExpenseAudit, startDate]);

  useEffect(() => {
    if (isIncomeError) {
      errorHandler(incomeError);
    }
  }, [incomeError, isIncomeError]);
  useEffect(() => {
    if (isExpenseError) {
      errorHandler(expenseError);
    }
  }, [expenseError, isExpenseError]);
  if (isIncomeLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }
  let totalCompanyIncome = 0;
  let totalCompanyExpense = 0;
  let totalRevenue = 0;
  if (incomeData && expenseData) {
    totalCompanyIncome = incomeData.totalCompanyIncome;
    totalCompanyExpense = expenseData.totalCompanyVehicleExpenses;
    totalRevenue = totalCompanyIncome - totalCompanyExpense;
  }
  return (
    <div>
      <div className="mt-3 flex flex-wrap gap-4">
        <DatePickerIncomeDemo date={date} setDate={handleDateChange} />
        <DatePickerIncomeDemoToDate
          date={toDate}
          setDate={handleToDateChange}
        />
      </div>
      <div className="mt-6 overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
            <Table className="min-w-full md:min-w-[1000px] lg:min-w-[1200px] divide-y divide-gray-200 bg-customLightBlue">
              <TableHeader>
                <TableRow>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Company Income
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Company Expense
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Revenue
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="bg-white divide-y divide-gray-200">
                {incomeData &&
                incomeData.totalCompanyIncome &&
                expenseData &&
                expenseData.totalCompanyVehicleExpenses ? (
                  <TableRow className="hover:bg-gray-50">
                    <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      KES: {incomeData.totalCompanyIncome}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      KES:{expenseData.totalCompanyVehicleExpenses}
                    </TableCell>
                    <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      KES: {totalRevenue < 0 ? 0 : totalRevenue}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={9}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No data. Toggle the dates to see something.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
