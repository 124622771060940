import React, { useEffect, useState } from "react";
import { useLazyGetOwnerDataQuery } from "../../api/busLineUserApi";
import { useOwnerAuth } from "../../api/ownerAuth";
import { errorHandler } from "../../error/index";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../components/ui/table";
import { formatTimestamp } from "../utility_components/utility_functions/utilityFunctions";
import { Loader2 } from "lucide-react";
import OwnerInputDropdown from "../utility_components/OwnerInputDropDown";
import { DatePickerOwnerDemo } from "../../components/ui/DatePickerOwnerDemo";
import { useGetOwnerSpecificVehicleQuery } from "../../api/busLineUserApi";

export default function OwnerView() {
  const { email } = useOwnerAuth();
  const [getOwnerData, { isError, error, isLoading }] =
    useLazyGetOwnerDataQuery();
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [date, setDate] = useState<Date | undefined>();
  const formattedDate = date
  ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`
  : "";
  const {
    data: specificVehicleData,
    isError: specificVehicleError,
    isLoading: specificVehicleLoading,
    error:specificVehcError,
    refetch,
  } = useGetOwnerSpecificVehicleQuery(
    { email, selectedVehicle, formattedDate },
    { skip: !email || !selectedVehicle }
  );
  useEffect(() => {
    if (selectedVehicle && formattedDate) {
      refetch()
    }
    if (email) {
      getOwnerData(email);
    }
    if (isError) {
      errorHandler(error);
    }
    if (specificVehicleError) {
      errorHandler(specificVehcError);
    }
  }, [date, email, error, formattedDate, getOwnerData, isError, refetch, selectedVehicle, specificVehcError, specificVehicleError]);
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }
  if (specificVehicleLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }
  return (
    <>
       <div className="bg-[#7485BF] text-white text-center p-4 text-xl sm:text-2xl md:text-3xl font-semibold mb-3">
        {specificVehicleData &&
          specificVehicleData.totalExpense &&
          `Total Expenses: Ksh ${
            specificVehicleData.totalExpense?.toFixed(2) ?? "0.00"
          }`}
      </div>
      <div className="flex space-x-4 mb-6">
        <OwnerInputDropdown
          placeHolder="Select Vehicle to filter Data"
          onValueChange={setSelectedVehicle}
          value={selectedVehicle}
        />
        <DatePickerOwnerDemo date={date} setDate={setDate} />
      </div>

      <div className="inline-block w-full align-middle">
        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
          <Table className="min-w-full md:min-w-[800px] lg:min-w-[1000px] divide-y divide-gray-200 bg-customLightBlue">
            <TableHeader>
              <TableRow>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Vehicle Reg No
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Expense
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Receipt Photo
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount Spent
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Driver Name
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="bg-white divide-y divide-gray-200">
              {specificVehicleData && specificVehicleData.reports && specificVehicleData.reports.length > 0 ? (
                specificVehicleData.reports
                  .map((singleReport) => (
                    <TableRow
                      key={singleReport.id}
                      className="hover:bg-gray-50"
                    >
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {singleReport.vehicle.registrationNumber}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {singleReport.description}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {singleReport.imageUrl ? (
                          <img
                            src={singleReport.imageUrl}
                            alt="Report"
                            className="h-10 w-10 object-cover rounded"
                          />
                        ) : (
                          "No Image"
                        )}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatTimestamp(singleReport.createdAt)}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        Ksh: {singleReport.revenue}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {`${singleReport.driver.firstName}  ${singleReport.driver.lastName}`}
                      </TableCell>
                    </TableRow>
                  ))
                  .reverse()
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                  >
                    No Driver Reports found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}
