import React from 'react';
import { Outlet } from 'react-router-dom';
import OwnerSideBar from '../Owner/OwnerSideBar';

function OwnerLayout() {
  return (
    <div className="flex h-screen">
      <OwnerSideBar />
      <main className="flex-1 px-4 py-6 sm:px-6">
        <Outlet />
      </main>
    </div>
  );
}

export default OwnerLayout;