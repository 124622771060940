import { configureStore } from "@reduxjs/toolkit";
import { busLineApi } from "../api/busLineApiData";
import errorReducer from "../slices/errorSlice";
import successReducer from "../slices/successSlice";
import loaderReducer from "../slices/loader";
import authReducer from "../slices/authSlice";
import warningReducer from "../slices/showWarnings";
import userAuthReducer from "../slices/userSlice";
import { busLineUserApi } from "../api/busLineUserApi";
import toastSuccess from "../slices/toastSuccess";
import { busLineOfficeApi } from "../api/busLineOfficeApi";
import companyAuthSlice from "../slices/companyAuth";
import  OwnerAuthSlice  from "../slices/setOwnerAuth";

export const store = configureStore({
  reducer: {
    [busLineApi.reducerPath]: busLineApi.reducer,
    [busLineUserApi.reducerPath]: busLineUserApi.reducer,
    [busLineOfficeApi.reducerPath]: busLineOfficeApi.reducer,
    error: errorReducer,
    success: successReducer,
    loader: loaderReducer,
    auth: authReducer,
    warning: warningReducer,
    userAuth: userAuthReducer,
    toastSuccess: toastSuccess,
    companyAuth: companyAuthSlice,
    ownerAuth:OwnerAuthSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(busLineApi.middleware)
      .concat(busLineUserApi.middleware).concat(busLineOfficeApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
