import React from 'react';
import { Outlet } from 'react-router-dom';
import DriverSideBar from './DriverSideBar';

function DriverLayout() {
  return (
    <div className="flex h-screen">
      <DriverSideBar />
      <main className="flex-1 px-4 py-6 sm:px-6">
        <Outlet />
      </main>
    </div>
  );
}

export default DriverLayout;