import React, { useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useGetCompanyFleetFromOfficeQuery } from "../../api/busLineOfficeApi";
import { errorHandler } from "../../error/index";
import { useOfficeUserAuth } from "../../api/officeAuth";

export default function VehicleParcelInputDropdown({
  value,
  onValueChange,
  placeHolder,
}) {
  const {companyId,} = useOfficeUserAuth()
  const {
    isError,
    error,
    data: fleet,
  } = useGetCompanyFleetFromOfficeQuery(companyId, {
    skip:  !companyId,
  });

  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue placeholder={placeHolder} />
      </SelectTrigger>
      <SelectContent>
        {fleet &&
        fleet.fleet &&
        fleet.fleet.vehicles &&
        fleet.fleet.vehicles.length > 0 ? (
          fleet.fleet.vehicles.map((singleVehicle) => (
            <SelectItem
              key={singleVehicle.id}
              value={singleVehicle.id}
            >
              {singleVehicle.registrationNumber}
            </SelectItem>
          ))
        ) : (
          <SelectItem value="none">No vehicles available</SelectItem>
        )}
      </SelectContent>
    </Select>
  );
}
