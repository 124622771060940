import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showWarnings: false,
    warningMessage: '',
  };
  
  const warningSlice = createSlice({
    name: 'warning',
    initialState,
    reducers: {
      setWarning: (state, action) => {
        state.showWarnings = true;
        state.warningMessage = action.payload;
      },
      clearWarning: (state) => {
        state.showWarnings = false;
        state.warningMessage = '';
      },
    },
  });
  
  export const { setWarning, clearWarning } = warningSlice.actions;
  export default warningSlice.reducer;