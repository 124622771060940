import React, { useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useLazyGetOwnerDataQuery } from "../../api/busLineUserApi";
import { useOwnerAuth } from "../../api/ownerAuth";
import { errorHandler } from "../../error/index";


export default function OwnerInputDropdown({
  value,
  onValueChange,
  placeHolder,
}) {
    const { email } = useOwnerAuth();
    const [getOwnerData, { isError, error, data }] = useLazyGetOwnerDataQuery();

  useEffect(() => {
    if (email) {
        getOwnerData(email);
      }
      if (isError) {
        errorHandler(error);
      }
  }, [email, error, getOwnerData, isError]);
  console.log(value);

  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className="w-64">
        <SelectValue placeholder={placeHolder} />
      </SelectTrigger>
      <SelectContent>
        { data && data.reports && data.reports.length > 0 ? (
           data.reports.map((singleReport) => (
            <SelectItem key={singleReport.vehicle.id} value={singleReport.vehicle.registrationNumber}>
              {singleReport.vehicle.registrationNumber}
            </SelectItem>
          ))
        ) : (
          <SelectItem value="none">No vehicles registered to this owner.</SelectItem>
        )}
      </SelectContent>
    </Select>
  );
}
