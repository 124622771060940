import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../image/logo.png";
import { usePostSpecificUserSignUpMutation } from "../../api/busLineApiData";
import { InputWithDropdown } from "../utility_components/InputWithDropdown";
import { errorHandler } from "../../error/index";
import { validateKenyanPhoneNumber } from "../utility_components/utility_functions/utilityFunctions";

interface UserFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  confirmPassword: string;
}

interface UserFormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  phoneNumber?: string;
}

function UserSignUp() {
  const [selectedValue, setSelectedValue] = useState("CUSTOMER");
  const [specificUserFormData, setFormData] = useState<UserFormData>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState<UserFormErrors>({});
  const [signUp, { isLoading }] = usePostSpecificUserSignUpMutation();
  const [showError, setShowError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    let newErrors: UserFormErrors = {};

    if (!specificUserFormData.firstName.trim())
      newErrors.firstName = "First name is required";
    if (!specificUserFormData.lastName.trim())
      newErrors.lastName = "Last name is required";
    if (!specificUserFormData.email.trim())
      newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(specificUserFormData.email))
      newErrors.email = "Email is invalid";
    if (!specificUserFormData.password)
      newErrors.password = "Password is required";
    if (specificUserFormData.password !== specificUserFormData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match !";
    else if (specificUserFormData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters";
    if (!specificUserFormData.phoneNumber.trim())
      newErrors.phoneNumber = "Phone number is required";
    else if (!validateKenyanPhoneNumber(specificUserFormData.phoneNumber))
      newErrors.phoneNumber =
        "Invalid Phone number. Use the 07... format";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (showError) {
      const timer = setTimeout(() => {
        setShowError(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showError]);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const userData = {
        ...specificUserFormData,
        role: selectedValue,
      };
      const result = await signUp(userData);
      if ("data" in result) {
        navigate("/normal-user-login", { replace: true });
        console.log("User SignUp successful", result);
        specificUserFormData.firstName = "";
        specificUserFormData.email = "";
        specificUserFormData.lastName = "";
        specificUserFormData.password = "";
        specificUserFormData.phoneNumber = "";
        specificUserFormData.confirmPassword = "";
      } else {
        return errorHandler(result.error);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-customLightBlue px-4 py-8">
        <div className="mb-8">
          <img src={logo} alt="Logo" className="h-24 w-38" />
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-lg p-6 md:p-8 space-y-6 bg-customLightBlue rounded-md shadow-md"
        >
          <h2 className="text-2xl font-bold text-center text-gray-800">
            Sign up as a User
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <Label htmlFor="firstName">First Name</Label>
              <Input
                id="firstName"
                name="firstName"
                value={specificUserFormData.firstName}
                onChange={handleChange}
                placeholder="First Name"
              />
              {errors.firstName && (
                <span className="text-red-500 text-sm">{errors.firstName}</span>
              )}
            </div>
            <div>
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                id="lastName"
                name="lastName"
                value={specificUserFormData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
              {errors.lastName && (
                <span className="text-red-500 text-sm">{errors.lastName}</span>
              )}
            </div>
          </div>

          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              value={specificUserFormData.email}
              onChange={handleChange}
              placeholder="Email"
            />
            {errors.email && (
              <span className="text-red-500 text-sm">{errors.email}</span>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="signUpOption">Sign up as..</Label>
            <InputWithDropdown
              className="w-full"
              defaultValue={"CUSTOMER"}
              placeHolder={"Choose an option"}
              value={selectedValue}
              onValueChange={setSelectedValue}
            />
          </div>

          <div>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              name="password"
              type="password"
              value={specificUserFormData.password}
              onChange={handleChange}
              placeholder="Password"
            />
            {errors.password && (
              <span className="text-red-500 text-sm">{errors.password}</span>
            )}
          </div>
          <div>
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              id="cPassword"
              name="confirmPassword"
              type="password"
              value={specificUserFormData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
            />
            {errors.confirmPassword && (
              <span className="text-red-500 text-sm">
                {errors.confirmPassword}
              </span>
            )}
          </div>

          <div>
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <Input
              id="phoneNumber"
              name="phoneNumber"
              value={specificUserFormData.phoneNumber}
              onChange={handleChange}
              placeholder="Phone Number"
              type="number"
            />
            {errors.phoneNumber && (
              <span className="text-red-500 text-sm">{errors.phoneNumber}</span>
            )}
          </div>
          <Button
            type="submit"
            className="w-full bg-customDarkBlue text-white"
            disabled={isLoading}
          >
            {isLoading ? "Creating Account..." : "Create Your Account"}
          </Button>
          <p className="text-center text-sm text-gray-500">
            Already have an account?{" "}
            <Link to="/normal-user-login" className="text-blue-600">
              Log In....
            </Link>
          </p>
        </form>
      </div>
    </>
  );
}

export default UserSignUp;
