import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string[] = [];

const toastSuccessSlice = createSlice({
  name: "success",
  initialState,
  reducers: {
    setSuccess: (state, action: PayloadAction<string>) => {
      state.push(action.payload);
    },
    clearSuccess: (state, action: PayloadAction<string>) => {
      // find index of the success message
      const index = state.findIndex(error => error === action.payload);
      // remove the success message
      state.splice(index, 1);
      return state;
    },
  },
});

export const {setSuccess, clearSuccess } = toastSuccessSlice.actions;
export default toastSuccessSlice.reducer;
