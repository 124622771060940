import React, { useState } from "react";
import { Button } from "../../components/ui/button";
import OfficeAndRoutes from "./OfficeAndRoutes";
import FareAmount from "./FareAmount";
import CompanyRoutesAndFareTable from "./RoutesAndFareTable/route_fare_page";

export default function RoutesAndFare() {
  const [isRouteDialogOpen, setIsRouteDialogOpen] = useState(false);
  const [isFareDialogOpen, setIsFareDialogOpen] = useState(false);
  const [selectedRouteId, setSelectedRouteId] = useState<string | null>(null);

  const openRouteDialog = () => setIsRouteDialogOpen(true);
  const closeRouteDialog = () => setIsRouteDialogOpen(false);

  const openFareDialog = (routeId: string) => {
    setSelectedRouteId(routeId);
    setIsFareDialogOpen(true);
  };

  const closeFareDialog = () => {
    setIsFareDialogOpen(false);
    setSelectedRouteId(null);
  };

  return (
    <>
      <Button
        className="bg-customDarkBlue text-white mx-auto mt-4 block"
        onClick={openRouteDialog}
      >
        Add Routes
      </Button>

      <CompanyRoutesAndFareTable openFareDialog={openFareDialog} />

      <OfficeAndRoutes isOpen={isRouteDialogOpen} onClose={closeRouteDialog} />
      
      {selectedRouteId && (
        <FareAmount
          isOpen={isFareDialogOpen}
          onClose={closeFareDialog}
          routeId={selectedRouteId}
        />
      )}
    </>
  );
}