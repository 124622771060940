import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import {
  OwnerEmail,
  OwnerEmailError,
} from "../utility_components/utility_functions/utilityFunctions";
import { useLazyGetOwnerDataQuery } from "../../api/busLineUserApi";
import { errorHandler } from "../../error/index";
import { useNavigate } from "react-router-dom";

export default function OwnerPrompt({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [postOwner] = useLazyGetOwnerDataQuery();
  const [ownerData, setOwnerData] = useState<OwnerEmail>({
    ownerEmail: "",
  });
  const [errors, setErrors] = useState<OwnerEmailError>({});
  const validateForm = () => {
    let newErrors: OwnerEmailError = {};

    if (!ownerData.ownerEmail.trim())
      newErrors.ownerEmail = "Owner email is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setOwnerData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
        const result = await postOwner(ownerData.ownerEmail);
      if ("data" in result) {
        const userEmail = {
          email: result.data.ownerEmail,
        };
        localStorage.setItem("ownerData", JSON.stringify(userEmail));
        navigate("/owner/home", { replace: true });
        ownerData.ownerEmail = "";
        onClose();
      } else {
        onClose();
        return errorHandler(result.error);
      }
    }
  };
  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="w-[95vw] sm:w-[90vw] md:max-w-[650px] h-[95vh] sm:h-auto max-h-[95vh] flex flex-col overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader className="shrink-0">
            <DialogTitle className="text-xl sm:text-2xl">
              Enter owner email.
            </DialogTitle>
          </DialogHeader>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col flex-grow overflow-hidden"
          >
            <div className="flex-grow overflow-auto bg-customLightBlue p-4">
              <div className="space-y-4 min-w-[280px]">
                <div>
                  <Label
                    htmlFor="oEmail"
                    className="text-base sm:text-lg block mb-1"
                  >
                    Owner Email.
                  </Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Owner Email"
                    className="w-full"
                    name="ownerEmail"
                    value={ownerData.ownerEmail}
                    onChange={handleChange}
                  />
                  {errors.ownerEmail && (
                    <span className="text-red-500 text-xs sm:text-sm block mt-1">
                      {errors.ownerEmail}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <DialogFooter className="mt-4 sm:mt-6 px-4 shrink-0">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-base sm:text-lg"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
