import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { clearAuth } from "../slices/authSlice";
import { jwtDecode } from "jwt-decode";

interface JwtPayLoadData {
  email: string;
  exp: number;
  iat: number;
  phoneNumber: string;
  role: string;
  userId: string;
  userName: string;
}

export function useTokenExpiration() {
  const [isExpired, setIsExpired] = useState(false);
  const dispatch = useDispatch();

  const checkTokenExpiration = useCallback(() => {
    const authToken = localStorage.getItem("companyToken");
    if (authToken) {
      try {
        const decodedToken = JSON.parse(authToken);
        const tokenData = jwtDecode<JwtPayLoadData>(decodedToken.token);
        const expirationTime = tokenData.exp * 1000; // Convert to milliseconds
        const currentTime = Date.now();

        if (currentTime >= expirationTime) {
        
          setIsExpired(true);
          dispatch(clearAuth());
          localStorage.removeItem("companyToken");
        } else {
          setIsExpired(false);
        }
      } catch (error) {
        setIsExpired(true);
        dispatch(clearAuth());
        localStorage.removeItem("companyToken");
      }
    } else {
      console.log("No token found in localStorage");
    }
  }, [dispatch]);

  useEffect(() => {
    checkTokenExpiration(); // Check immediately on mount
    const interval = setInterval(checkTokenExpiration,  30 * 60 * 1000); // Check every 30 minutes for testing
    // const interval = setInterval(checkTokenExpiration, 30 * 1000);
    return () => {
      console.log("Cleaning up token expiration check..."); // New log
      clearInterval(interval);
    };
  }, [checkTokenExpiration]);

  useEffect(() => {
    console.log("isExpired state changed:", isExpired); // New log
  }, [isExpired]);

  return isExpired;
}
