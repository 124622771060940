import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  FareAmountRoute,
  FareAmountError,
} from "../utility_components/utility_functions/utilityFunctions";
import { usePostCompanyFareRatesMutation } from "../../api/busLineApiData";
import { useAuth } from "../../api/getCredentials";
import { setLoading } from "../../slices/loader";
import { useDispatch } from "react-redux";
import { clearError } from "../../slices/errorSlice";
import { useGetCompanyRoutesAndFareQuery } from "../../api/busLineApiData";
import { errorHandler } from "../../error/index";
import { successFunctionDisplay } from "../../success/success";

export default function FareAmount({ isOpen, onClose, routeId }) {
  const dispatch = useDispatch();
  const [fareData, setFareData] = useState<FareAmountRoute>({
    fareAmount: "",
  });

  const { token, companyId } = useAuth();
  const [postFareRates, { isLoading }] = usePostCompanyFareRatesMutation();
  console.log(`Company Id is ${companyId}`);
  const { refetch } = useGetCompanyRoutesAndFareQuery(
    { companyId, page: 1 },
    {
      skip: !token || !companyId,
    }
  );
  const [errors, setErrors] = useState<FareAmountError>({});
  const validateForm = () => {
    let newErrors: FareAmountError = {};

    if (!fareData.fareAmount.trim())
      newErrors.fareAmount = "Fare amount is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFareData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const result = await postFareRates({
        fareData,
        companyId,
        routeId,
      });
      if ("data" in result) {
        console.log("Fare route added successfully", result);
        fareData.fareAmount = "";
        routeId = "";
        refetch();
        dispatch(setLoading(isLoading));
        successFunctionDisplay("Fare added successfully");
        onClose();
      } else {
        return errorHandler(result.error);
      }
      onClose();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Enter Fare For Route..
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue">
              <div>
                <Label htmlFor="fareAmount" className="text-lg">
                  Fare Amount
                </Label>
                <Input
                  id="fareAmount"
                  placeholder="Fare Amount"
                  className="mt-2"
                  type="number"
                  name="fareAmount"
                  onChange={handleChange}
                  value={fareData.fareAmount}
                />
                {errors.fareAmount && (
                  <span className="text-red-500 text-sm">
                    {errors.fareAmount}
                  </span>
                )}
              </div>
            </div>
            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-lg"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
