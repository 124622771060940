import React, { useEffect } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../components/ui/table";
import { useGetCompanyParcelsQuery } from "../../api/busLineApiData";
import { Loader2 } from "lucide-react";
import { useAuth } from "../../api/getCredentials";
import { errorHandler } from "../../error/index";
import { formatTimestamp } from "../utility_components/utility_functions/utilityFunctions";

export default function ParcelView({ openDialog }) {
  const { token, companyId } = useAuth(); // Your custom hook to get auth state
  const {
    isLoading: getParcelLoading,
    data: parcels,
    isError: isGetParcelError,
    error: parcelErrorMessage,
    isSuccess,
  } = useGetCompanyParcelsQuery(companyId ?? "", {
    skip: !token || !companyId,
  });


  useEffect(() => {
    if (isGetParcelError) {
      errorHandler(parcelErrorMessage);
    }
  }, [isGetParcelError, parcelErrorMessage]);

  if (getParcelLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }
  
  return (
    <>
      <div className="mt-6 overflow-hidden rounded-lg border">
      <div className="bg-[#7485BF] text-white text-center p-4 text-xl sm:text-2xl md:text-3xl font-semibold">
        {parcels &&
          parcels.totalAmountOnParcels &&
          `Total Sales: Ksh ${
            parcels.totalAmountOnParcels?.toFixed(2) ?? "0.00"
          }`}
      </div>
      <div className="mt-6 overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
            <Table className="min-w-full md:min-w-[1000px] lg:min-w-[1200px] divide-y divide-gray-200 bg-customLightBlue">
              <TableHeader>
                <TableRow>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Parcel ID
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Sender Name
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Recipient Name
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Destination (From)
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Destination (To)
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Fee Charges
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Recipient Phone
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Time Stamp
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Vehicle Reg No
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Office
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="bg-white divide-y divide-gray-200">
                {isSuccess &&
                parcels &&
                parcels.specificCompany &&
                parcels.specificCompany.parcels &&
                parcels.specificCompany.parcels.length > 0 ? (
                  parcels.specificCompany.parcels
                    .map((parcel) => (
                      <TableRow key={parcel.id} className="hover:bg-gray-50">
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {parcel.id}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.senderName}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.recipientName}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.fromLocation}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.toLocation}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.fees}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.recipientPhone}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatTimestamp(parcel.sentAt)}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.vehicle.registrationNumber}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.office.name}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {parcel.status}
                        </TableCell>
                      </TableRow>
                    ))
                    .reverse()
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={9}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No parcels found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        </div>
        </div>
    </>
  );
}
