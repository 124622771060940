import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../slices/types";
import { BASE_URL, LOCAL_BASEURL } from "../constants/constants";

export const busLineUserApi = createApi({
  reducerPath: "busLineUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // baseUrl: LOCAL_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const { token } = state.userAuth;
      if (token) {
        headers.set("token", token);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDriverVehicleId: builder.query({
      query: (email) => ({
        url: `/get-vehicleId?email=${email}`,
        method: "GET",
      }),
    }),

    postDriverSubmitVehicleReport: builder.mutation({
      query: ({ driverReportData }) => ({
        url: "/submitVehicleReport",
        method: "POST",
        body: driverReportData,
      }),
    }),
    getSpecificDriverReports: builder.query({
      query: (driverId) => ({
        url: `/get-driverReports?driverId=${driverId}`,
        method: "GET",
      }),
    }),
    getDriverIncome: builder.query({
      query: ({ vehicleId, dateString }) => ({
        url: `/get-driverIncome?vehicleId=${vehicleId}&date=${dateString}`,
        method: "GET",
      }),
    }),
    postDriverEndTrip: builder.mutation({
      query: ({ vehicleId, driverId, vehicleCapacity }) => ({
        url: "/end-trip",
        method: "POST",
        body: {
          vehicleId,
          driverId,
          vehicleCapacity: parseInt(vehicleCapacity),
        },
      }),
    }),
    getOwnerData: builder.query({
      query: (ownerEmail) => ({
        url: `/owner/get-report?email=${ownerEmail}`,
        method: "GET",
      }),
    }),
    getOwnerSpecificVehicle: builder.query({
      query: ({ email, selectedVehicle, formattedDate }) => ({
        url: `/owner/get-specific-vehicle-report?email=${email}&regNo=${selectedVehicle}&date=${formattedDate}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDriverVehicleIdQuery,
  usePostDriverSubmitVehicleReportMutation,
  useGetSpecificDriverReportsQuery,
  useGetDriverIncomeQuery,
  useLazyGetDriverIncomeQuery,
  usePostDriverEndTripMutation,
  useLazyGetOwnerDataQuery,
  useGetOwnerSpecificVehicleQuery,
} = busLineUserApi;
export const { getDriverVehicleId } = busLineUserApi.endpoints;
