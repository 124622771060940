import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../components/ui/table";
import { DatePickerDemo } from "../../components/ui/DatePickerDemo";
import {
  useGetDriverVehicleIdQuery,
  useGetDriverIncomeQuery,
} from "../../api/busLineUserApi";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useNormalUserAuth } from "../../api/NormalUserAuth";
import { errorHandler } from "../../error/index";
import { Loader2 } from "lucide-react";
import { Badge } from "../../components/ui/badge";
import { useGetSpecificDriverReportsQuery } from "../../api/busLineUserApi";

import { Button } from "../../components/ui/button";
import ResetModal from "./ResetModal";
import { formatTimestamp } from "../utility_components/utility_functions/utilityFunctions";

export default function DriverPage() {
  const { email } = useNormalUserAuth();
  const [isResetOpen, setResetOpen] = useState(false);
  const openReset = () => setResetOpen(true);
  const closeReset = () => setResetOpen(false);
  const [date, setDate] = React.useState<Date | undefined>();
  const formattedDate = date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`
    : "";
  console.log(formattedDate);

  const {
    data: vehicleData,
    isLoading: isVehicleLoading,
    isError: isVehicleError,
    error: vehicleError,
  } = useGetDriverVehicleIdQuery(email ?? skipToken);
  const {
    data: driverIncomeData,
    error: incomeError,
    isLoading: isIncomeLoading,
    isError: isIncomeError,
    refetch,
  } = useGetDriverIncomeQuery(
    { vehicleId: vehicleData?.vehicleId, dateString: formattedDate },
    { skip: !vehicleData?.vehicleId }
  );
  console.log(driverIncomeData);

  const handleDateChange = useCallback((newDate: Date | undefined) => {
    setDate(newDate);
    console.log("Date changed:", newDate);
  }, []);

  useEffect(() => {
    console.log(
      "Effect running. FormattedDate:",
      formattedDate,
      "VehicleId:",
      vehicleData?.vehicleId
    );
    if (formattedDate && vehicleData?.vehicleId) {
      console.log("Refetching with:", {
        vehicleId: vehicleData.vehicleId,
        dateString: formattedDate,
      });
      refetch();
    }
  }, [formattedDate, vehicleData?.vehicleId, refetch]);

  useEffect(() => {
    console.log("Driver Income Data:", driverIncomeData);
  }, [driverIncomeData]);
  const { data, error, isError } = useGetSpecificDriverReportsQuery(email);

  useEffect(() => {
    if (isVehicleError) {
      errorHandler(vehicleError);
    }
    if (isError) {
      errorHandler(error);
    }
    if (isIncomeError) {
      errorHandler(incomeError);
    }
  }, [
    isVehicleError,
    isIncomeError,
    vehicleError,
    incomeError,
    isError,
    error,
  ]);

  if (isVehicleLoading || isIncomeLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }
  let totalIncome = 0.0;

  if (data && driverIncomeData) {
    const totalExpenses = data.totalExpenses ?? 0;
    const totalIncomeValue = driverIncomeData.totalIncome ?? 0;

    if (totalExpenses > totalIncomeValue) {
      totalIncome = 0.0;
    } else {
      totalIncome = totalIncomeValue - totalExpenses;
    }
  }

  // When displaying the totalIncome
  <span>Driver Revenue: {totalIncome.toFixed(2)}</span>;

  return (
    <>
      <div className="mb-3 overflow-x-auto">
        <Button
          className="bg-customDarkBlue text-white mx-auto mt-4 block"
          onClick={openReset}
        >
          Reset Vehicle Status & Capacity
        </Button>
        <div className="mt-3">
          <DatePickerDemo date={date} setDate={handleDateChange} />
        </div>
      </div>

      {driverIncomeData &&
        driverIncomeData.trips &&
        driverIncomeData.trips.length > 0 && (
          <div
            className="flex justify-between gap-4"
            style={{
              backgroundColor: "#7485BF",
              color: "white",
              textAlign: "center",
              padding: "10px",
              fontSize: "30px",
            }}
          >
            <span>
              Total Income: Ksh{" "}
              {driverIncomeData.totalIncome?.toFixed(2) ?? "0.00"}
            </span>
            <span>Ticket Count: {driverIncomeData.ticketCount ?? "0"}</span>
            <span>
              Driver Expenditure: {data?.totalExpenses?.toFixed(2) ?? "0.00"}
            </span>
            <span>
              Driver Revenue:
              {(
                (driverIncomeData.totalIncome ?? 0) - (data?.totalExpenses ?? 0)
              ).toFixed(2)}
            </span>
          </div>
        )}

      <div className="inline-block w-full align-middle">
        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
          <Table className="w-full min-w-[90%] md:min-w-[800px] lg:min-w-[1000px] divide-y divide-gray-200 bg-customLightBlue">
            <TableHeader>
              <TableRow>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ticket ID
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fare Revenue
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Vehicle Reg Number
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Travel Date
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="bg-white divide-y divide-gray-200">
              {driverIncomeData?.trips && driverIncomeData.trips.length > 0 ? (
                driverIncomeData.trips
                  .flatMap((trip) => trip.tickets) // Flatten the tickets from all trips into a single array
                  .map((ticket) => (
                    <TableRow key={ticket.id} className="hover:bg-gray-50">
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {ticket.ticketNumber}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {ticket.passengerName}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {ticket.fare}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {driverIncomeData.vehicle.registrationNumber}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatTimestamp(ticket.travelDate)}{" "}
                      </TableCell>
                      <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <Badge variant="outline">PAID</Badge>
                      </TableCell>
                    </TableRow>
                  ))
                  .reverse()
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                  >
                    No income reports found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      {vehicleData && vehicleData?.vehicleId && (
        <ResetModal
          isOpen={isResetOpen}
          onClose={closeReset}
          vehicleId={vehicleData.vehicleId}
        />
      )}
    </>
  );
}
