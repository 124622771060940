import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from "../utility_components/SideBar";

function Layout() {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <main className="flex-1 px-4 py-6 sm:px-6">
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;