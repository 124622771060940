// useAuth.ts
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../slices/types";
import { setOwnerAuth } from "../slices/setOwnerAuth";

export function useOwnerAuth() {
  const dispatch = useDispatch();
  const { email } = useSelector((state: RootState) => state.ownerAuth);

  useEffect(() => {
    try {
      const ownerAuth = localStorage.getItem("ownerData");
      if (ownerAuth) {
        const decodedToken = JSON.parse(ownerAuth);
        dispatch(
          setOwnerAuth({
            email: decodedToken.email,
          })
        );
      }
    } catch (error) {
      console.error("Error parsing token:", error);
    }
  }, [dispatch]);

  return { email };
}
