import React, { useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useGetCompanyFleetFromOfficeQuery } from "../../api/busLineOfficeApi";
import { errorHandler } from "../../error/index";
import { useOfficeUserAuth } from "../../api/officeAuth";

interface Vehicle {
  id: string;
  registrationNumber: string;
}

interface Fleet {
  vehicles: Vehicle[];
}

interface CompanyFleetResponse {
  fleet: Fleet;
}

interface VehicleInputDropdownProps {
  value: string;
  onValueChange: (value: string) => void;
  placeHolder: string;
}

export default function VehicleInputDropdown({
  value,
  onValueChange,
  placeHolder,
}: VehicleInputDropdownProps) {
  const { companyId } = useOfficeUserAuth();
  const {
    isError,
    error,
    data: fleetData,
  } = useGetCompanyFleetFromOfficeQuery(companyId!, {
    skip: !companyId,
  });

  useEffect(() => {
    if (isError && error) {
      errorHandler(error);
    }
  }, [error, isError]);

  const fleet = fleetData as CompanyFleetResponse | undefined;

  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger>
        <SelectValue placeholder={placeHolder} />
      </SelectTrigger>
      <SelectContent>
        {fleet?.fleet?.vehicles && fleet.fleet.vehicles.length > 0 ? (
          fleet.fleet.vehicles.map((singleVehicle) => (
            <SelectItem
              key={singleVehicle.id}
              value={singleVehicle.registrationNumber}
            >
              {singleVehicle.registrationNumber}
            </SelectItem>
          ))
        ) : (
          <SelectItem value="none">No vehicles available</SelectItem>
        )}
      </SelectContent>
    </Select>
  );
}
