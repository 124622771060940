import React, { useState, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../components/ui/table";
import { useGetSpecificDriverReportsQuery } from "../../api/busLineUserApi";
import { Button } from "../../components/ui/button";
import { useNormalUserAuth } from "../../api/NormalUserAuth";
import { AddDriverExpense } from "./AddDriverExpense";
import { getDriverVehicleId } from "../../api/busLineUserApi";
import { store } from "../../store/busLineStore";
import { Loader2, PencilIcon, TrashIcon } from "lucide-react";
import { errorHandler } from "../../error/index";
import { formatTimestamp } from "../utility_components/utility_functions/utilityFunctions";

export default function DriverExpenses() {
  const { email, userId } = useNormalUserAuth();
  const [isDriverDialogOpen, setIsDriverDialog] = useState(false);
  const openDialog = () => setIsDriverDialog(true);
  const closeDialog = () => setIsDriverDialog(false);
  const { isLoading, data, error, isError } =
    useGetSpecificDriverReportsQuery(userId);
  useEffect(() => {
    if (email) {
      store.dispatch(getDriverVehicleId.initiate(email));
    }
    if (isError) {
      errorHandler(error);
    }
  }, [email, error, isError]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }
  return (
    <>
     
      <Button
        className="bg-customDarkBlue text-white mx-auto mt-4 block"
        onClick={openDialog}
      >
        Add Expense
      </Button>
    
     
      <div className="mt-6 overflow-x-auto">
        {data &&data.totalExpenses&& ( <div
          style={{
            backgroundColor: "#7485BF",
            color: "white",
            textAlign: "center",
            padding: "10px",
            fontSize: "30px",
          }}
        >
          Total Expenditure: Ksh {data.totalExpenses.toFixed(2) ?? "0.00"}
        </div>) }
       
        <div className="inline-block w-full align-middle">
          <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
            <Table className="min-w-full md:min-w-[800px] lg:min-w-[1000px] divide-y divide-gray-200 bg-customLightBlue">
              <TableHeader>
                <TableRow>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Vehicle Reg No
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Expense
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Receipt Photo
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </TableHead>
                  <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount Spent
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="bg-white divide-y divide-gray-200">
                {data && data.reports && data.reports.length > 0 ? (
                  data.reports
                    .map((singleReport) => (
                      <TableRow
                        key={singleReport.id}
                        className="hover:bg-gray-50"
                      >
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {singleReport.vehicle.registrationNumber}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {singleReport.description}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {singleReport.imageUrl ? (
                            <img
                              src={singleReport.imageUrl}
                              alt="Report"
                              className="h-10 w-10 object-cover rounded"
                            />
                          ) : (
                            "No Image"
                          )}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatTimestamp( singleReport.createdAt)}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Ksh: {singleReport.revenue}
                        </TableCell>
                        <TableCell className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <Button variant="ghost" className="mr-2">
                            <PencilIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            Update
                          </Button>
                          <Button variant="ghost" color="red">
                            <TrashIcon className="h-5 w-5" aria-hidden="true" />{" "}
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                    .reverse()
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No Driver Reports found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      {isDriverDialogOpen && (
        <AddDriverExpense isOpen={isDriverDialogOpen} onClose={closeDialog} />
      )}
    </>
  );
}
