// useAuth.ts
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../slices/types";
import { setCompanyAuth } from "../slices/companyAuth";

export function useOfficeUserAuth() {
  const dispatch = useDispatch();
  const { token, companyId, officeId, officeName, role } = useSelector(
    (state: RootState) => state.companyAuth
  );

  useEffect(() => {
    try {
      const authToken = localStorage.getItem("officeData");
      if (authToken) {
        const decodedToken = JSON.parse(authToken);
        dispatch(
          setCompanyAuth({
            token: decodedToken.token,
            companyId: decodedToken.companyId,
            officeId: decodedToken.officeId,
            officeName: decodedToken.officeName,
            role: decodedToken.role,
          })
        );
      }
    } catch (error) {
      console.error("Error parsing token:", error);
    }
  }, [dispatch]);

  return { token, companyId, officeId, officeName, role };
}
