// useAuth.ts
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../slices/types";
import { setAuth } from "../slices/authSlice";

export function useAuth() {
  const dispatch = useDispatch();
  const { token, companyId } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    try {
      const authToken = localStorage.getItem("companyToken");
      if (authToken) {
        const decodedToken = JSON.parse(authToken);
        dispatch(
          setAuth({
            token: decodedToken.token,
            companyId: decodedToken.companyId,
          })
        );
      }
    } catch (error) {
      console.error("Error parsing token:", error);
    }
  }, [dispatch]);

  return { token, companyId };
}
