import React from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";


export function InputWithDropdown({className, defaultValue,placeHolder, value, onValueChange}) {
  return (
    <Select defaultValue={defaultValue}  value={value} onValueChange={onValueChange}>
      <SelectTrigger className={`w-full ${className}`}>
        <SelectValue placeholder={placeHolder} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="DRIVER">DRIVER</SelectItem>
        <SelectItem value="CUSTOMER">CUSTOMER</SelectItem>
        <SelectItem value="PRIVATE_DRIVER">PRIVATE_DRIVER</SelectItem>
      </SelectContent>
    </Select>
  )
}