import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Mail, LogOut, Settings } from "lucide-react";
import logo from "../../image/logo.png";
import LogoutDialog from "../utility_components/LogoutDialog";
export default function OwnerSideBar() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "bg-blue-600 text-green" : "";
  };
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();

  const toggleLogoutDialog = () => setIsLogoutDialogOpen(!isLogoutDialogOpen);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
  };
  return (
    <div className="flex">
      <aside className="fixed top-0 left-0 h-full flex flex-col border-r bg-customLightBlue px-4 py-6 sm:px-6 md:w-60 lg:w-72 overflow-y-auto z-50">
        <div className="mb-8">
          <img src={logo} alt="Logo" className="h-24 w-38" />
        </div>
        <div className="mt-8 space-y-5">
          <div className="text-lg font-bold">Owner Dashboard</div>
          <nav className="space-y-5">
            <Link
              to="/owner/home"
              className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:bg-muted ${isActive(
                "/owner/home"
              )}`}
              replace
            >
              <Mail className="h-5 w-5" />
              Driver Expenses
            </Link>
          </nav>
        </div>
        <div className="mt-auto space-y-2">
          <div className="text-lg font-bold">General</div>
          <nav className="space-y-1">
            <button
              className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:bg-muted w-full text-left ${
                isActive("/employee") ? "bg-muted" : ""
              }`}
              onClick={toggleLogoutDialog}
            >
              <LogOut className="h-5 w-5" />
              Logout
            </button>
            <Link
              to=""
              className="flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium hover:bg-muted"
            >
              <Settings className="h-5 w-5" />
              Settings
            </Link>
          </nav>
        </div>
      </aside>
      <main className="flex-1 ml-[15rem] lg:ml-[19rem] p-6 overflow-y-auto"></main>
      <LogoutDialog
        isOpen={isLogoutDialogOpen}
        onClose={toggleLogoutDialog}
        onConfirm={handleLogout}
      />
    </div>
  );
}
