import React from "react";

export default function TripsView() {
  return (
    <div>
      <div className="mt-10 text-lg">
        <h1>Nothing to display...</h1>
      </div>
    </div>
  );
}
