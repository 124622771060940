import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserAuthState {
  token: string | null;
  userId: string | null;
  userName: string | null;
  phoneNumber: string | null;
  role: string | null;
  exp: Date | null;
  iat: Date | null;
  email:string| null
}

const initialState: UserAuthState = {
  token: null,
  userId: null,
  userName: null,
  phoneNumber: null,
  role: null,
  exp: null,
  iat: null,
  email:null
};

const authUserSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setUserAuth: (state, action: PayloadAction<UserAuthState>) => {
      state.token = action.payload.token;
      state.exp = action.payload.exp;
      state.iat = action.payload.iat;
      state.phoneNumber = action.payload.phoneNumber;
      state.role = action.payload.role;
      state.userId = action.payload.userId;
      state.userName = action.payload.userName;
      state.email = action.payload.email
    },
    clearAuth: (state) => {
      state.email = null;
      state.exp = null;
      state.iat = null;
      state.phoneNumber = null;
      state.role = null;
      state.token = null;
      state.userId = null;
      state.userName = null;
    }
  },
});

export const { setUserAuth,clearAuth } = authUserSlice.actions;
export default authUserSlice.reducer;
