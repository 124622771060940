import React, { useState, } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { useNormalUserAuth } from "../../api/NormalUserAuth";
import { useGetDriverVehicleIdQuery } from "../../api/busLineUserApi";
import { usePostDriverSubmitVehicleReportMutation } from "../../api/busLineUserApi";
import {skipToken}  from '@reduxjs/toolkit/query/react';
import { successFunctionDisplay } from "../../success/success";
import { errorHandler } from "../../error/index";
import { useGetSpecificDriverReportsQuery } from "../../api/busLineUserApi";



interface FormData {
  description: string;
  revenue: string;
  image?: File;
}
interface AddDriverExpenseProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddDriverExpense: React.FC<AddDriverExpenseProps> = ({
  isOpen,
  onClose,
}) => {
  const [formData, setFormData] = useState<FormData>({
    description: "",
    revenue: "",
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const { email,userId } = useNormalUserAuth();
  const { data, } = useGetDriverVehicleIdQuery(
    email?? skipToken
  );
  const [postData,] = usePostDriverSubmitVehicleReportMutation();
  const { refetch } =
  useGetSpecificDriverReportsQuery(userId);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name as keyof FormData]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData((prev) => ({ ...prev, image: e.target.files![0] }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validation
    const newErrors: Partial<FormData> = {};
    if (!formData.description.trim())
      newErrors.description = "Description is required";
    if (!formData.revenue.trim()) newErrors.revenue = "Revenue is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const driverReportData = {
      vehicleId:data.vehicleId,
      description: formData.description,
      revenue: formData.revenue,
      driverId:userId,
      image: formData.image ? formData.image : null,
    };

    console.log(driverReportData);
   
      const result = await postData({
        driverReportData
      });
      if ("data" in result) {
        console.log("Report Added successfully", result);
        formData.description = "";
        formData.revenue = "";
        onClose();
        refetch();
        successFunctionDisplay("Successfully added report");
      } else {
        onClose();
        return errorHandler(result.error);
      }
  };


  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogTitle className="text-2xl">Add Vehicle Expense</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}  encType="multipart/form-data">
            <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue">
              <div>
                <Label htmlFor="description" className="text-lg">
                  Description
                </Label>
                <Textarea
                  id="description"
                  placeholder="Expense Description"
                  className="mt-2"
                  name="description"
                  onChange={handleChange}
                  value={formData.description}
                />
                {errors.description && (
                  <span className="text-red-500 text-sm">
                    {errors.description}
                  </span>
                )}
              </div>
              <div>
                <Label htmlFor="revenue" className="text-lg">
                  Revenue Used
                </Label>
                <Input
                  id="revenue"
                  type="number"
                  placeholder="Revenue used"
                  className="mt-2"
                  name="revenue"
                  onChange={handleChange}
                  value={formData.revenue}
                />
                {errors.revenue && (
                  <span className="text-red-500 text-sm">{errors.revenue}</span>
                )}
              </div>
              <div>
                <Label htmlFor="image" className="text-lg">
                  Image (Optional)
                </Label>
                <Input
                  id="image"
                  type="file"
                  className="mt-2"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </div>
            </div>
            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full text-white py-2 text-lg bg-customDarkBlue"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
