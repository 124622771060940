import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./HomePage";
import UserLogin from "./custom_components/utility_components/UserLogin";
import BusinessHomePage from "./custom_components/business_components/BusinessHome";
import FleetOwners from "./custom_components/business_components/FleetOwners";
import Reports from "./custom_components/business_components/Reports";
import RoutesAndFare from "./custom_components/business_components/RoutesAndFare";
import TripsAndShares from "./custom_components/business_components/TripsAndShares";
import Layout from "./custom_components/utility_components/Layout";
import CompanyListOffice from "./custom_components/business_components/CompanyListOffice";
import Home from "./custom_components/Home/Home";
import UserSignUp from "./custom_components/Custom/UserSignUp";
import DriverPage from "./custom_components/Custom/DriverIncome";
import NormalUserLogin from "./custom_components/Custom/NormalUserLogin";
import CustomerPage from "./custom_components/Custom/CustomerPage";
import DriverLayout from "./custom_components/utility_components/DriverLayout";
import DriverExpenses from "./custom_components/Custom/DriverExpenses";
import { useToast } from "./components/ui/use-toast";
import { ToastAction } from "./components/ui/toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./slices/types";
import { clearError } from "./slices/errorSlice";
import { Toaster } from "./components/ui/toaster";
import { useTokenExpiration } from "./api/companyTokenChecker";
import { clearSuccess } from "./slices/toastSuccess";
import OfficeLayout from "./custom_components/utility_components/OfficeLayout";
import OfficeActions from "./custom_components/Custom/OfficeActions";
import OwnerLayout from "./custom_components/Owner/OwnerLayout";
import OwnerView from "./custom_components/Owner/OwnerView";
import { SessionExpiredDialog } from "./custom_components/utility_components/SessionExpiredDialog";
import { UserSessionExpiredDialog } from "./custom_components/utility_components/UserSessionExpiredDialog";
import AuditReport from "./custom_components/business_components/AuditReport";
import { useUserTokenExpiration } from "./api/NormalUserAuthTokenChecker";

function App() {
  const isTokenExpired = useTokenExpiration();
  const isUserTokenExpired = useUserTokenExpiration();
  const [showExpiredDialog, setShowExpiredDialog] = useState(false);
  const [showUserExpiredDialog, setShowUserExpiredDialog] = useState(false);
  const errors = useSelector((state: RootState) => state.error);
  const success = useSelector((state: RootState) => state.toastSuccess);
  const dispatch = useDispatch();
  const { toast } = useToast();



  useEffect(() => {
    if (isUserTokenExpired) {
      setShowUserExpiredDialog(true);
    }
  }, [isUserTokenExpired]);

  useEffect(() => {
    if (isTokenExpired) {
      setShowExpiredDialog(true);
    }
  }, [isTokenExpired]);

  useEffect(() => {
    if (errors.length > 0) {
      errors.forEach((error) => {
        toast({
          title: "Error",
          description: error,
          variant: "destructive",
          action: (
            <ToastAction
              onClick={() => dispatch(clearError(error))}
              altText="Clear Error"
            >
              Clear
            </ToastAction>
          ),
        });
      });
    }
  }, [dispatch, errors, toast]);

  useEffect(() => {
    if (success.length > 0) {
      success.forEach((success) => {
        toast({
          title: "Success",
          description: success,
          variant: "successVariant",
          action: (
            <ToastAction
              onClick={() => dispatch(clearSuccess(success))}
              altText="Clear Success"
            >
              Clear
            </ToastAction>
          ),
        });
      });
    }
  }, [dispatch, success, toast]);
  const handleCloseExpiredDialog = () => {
    setShowExpiredDialog(false);
  };
  const handleCloseUserExpiredDialog = () => {
    setShowUserExpiredDialog(false);
  };

  return (
    <>
      <Toaster />
      <Router>
        <SessionExpiredDialog
          isExpired={showExpiredDialog}
          onClose={handleCloseExpiredDialog}
        />
        <UserSessionExpiredDialog
          isUserTokenExpired={showUserExpiredDialog}
          onCloseDialog={handleCloseUserExpiredDialog}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/user-sign-up" element={<UserSignUp />} />
          <Route path="/sign-up" element={<HomePage />} />
          <Route path="/driver-page" element={<DriverPage />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/normal-user-login" element={<NormalUserLogin />} />
          <Route path="/customer-page" element={<CustomerPage />} />
          <Route path="/owner" element={<OwnerLayout />}>
            <Route index element={<OwnerView />} />
            <Route path="home" element={<OwnerView />} />
          </Route>
          <Route path="/office" element={<OfficeLayout />}>
            <Route index element={<OfficeActions />} />
            <Route path="home" element={<OfficeActions />} />
          </Route>
          <Route path="/driver" element={<DriverLayout />}>
            <Route index element={<DriverPage />} />
            <Route path="driver-home" element={<DriverPage />} />
            <Route path="driver-expenses" element={<DriverExpenses />} />
          </Route>
          <Route path="/biz" element={<Layout />}>
            <Route index element={<BusinessHomePage />} />
            <Route path="home" element={<BusinessHomePage />} />
            <Route path="fleet-owners" element={<FleetOwners />} />
            <Route path="reports" element={<Reports />} />
            <Route path="audit-report" element={<AuditReport />} />
            <Route path="routes-and-fare" element={<RoutesAndFare />} />
            <Route path="trips-shares" element={<TripsAndShares />} />
            <Route
              path="list-company-offices"
              element={<CompanyListOffice />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
