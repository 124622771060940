import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";

export const SessionExpiredDialog = ({ isExpired, onClose }: { isExpired: boolean; onClose: () => void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (isExpired) {
      setIsOpen(true);
    }
  }, [isExpired]);

  const handleReLogin = () => {
    setIsOpen(false);
    onClose();
    navigate('/login', {replace:true});
  };

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-xl">Your session has expired !</AlertDialogTitle>
          <AlertDialogDescription className="text-lg">
            Please log in again to continue using the application.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction onClick={handleReLogin} className={'bg-destructive hover:bg-destructive/80 text-destructive-foreground'}>
            {" "}
            Log In Again
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
