import React, { useEffect, useState } from "react";
import {
  CompanyReportTicketsData,
  columns,
} from "./company_tickets_reports_column";
import { DataTable } from "../Re-Usables/data-table";
import { useAuth } from "../../../api/getCredentials";
import { useGetCompanyFilteredTicketSalesQuery } from "../../../api/busLineApiData";
import { errorHandler } from "../../../error/index";
import { Loader2 } from "lucide-react";
import { Button } from "../../../components/ui/button";

export default function CompanyTicketsReportTable() {
  const { companyId, token } = useAuth();
  const [pageIndex, setPageIndex] = useState(0);
  const [period, setPeriod] = useState("today");
  const credentials = { companyId, period, page: pageIndex + 1 };
  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
  };
  const {
    isLoading,
    error,
    isError,
    data: ticketData,
  } = useGetCompanyFilteredTicketSalesQuery({ credentials });

  useEffect(() => {
    if (isError) {
      errorHandler(error);
    }
  }, [error, isError]);

  const prepareTableData = (): CompanyReportTicketsData[] => {
    if (
      !ticketData ||
      !ticketData.companyData ||
      !ticketData.companyData.offices
    ) {
      return [];
    }

    return ticketData.companyData.offices.flatMap((office) =>
      office.tickets.map((ticket) => ({
        ticketNumber: ticket.ticketNumber,
        userName: `${ticket.user?.firstName ?? ""} ${
          ticket.user?.lastName ?? ""
        }`,
        ticketId: ticket.id,
        userPhoneNumber: ticket.user?.phoneNumber ?? "N/A",
        from: ticket.route?.startPoint ?? "N/A",
        to: ticket.route?.endPoint ?? "N/A",
        price: ticket.price,
      }))
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  const tableData = prepareTableData();

  return (
    <div className="container mx-auto py-10">
      <div className="mb-4 flex flex-wrap gap-2">
        {[
          { label: "Today", value: "today" },
          { label: "This Week", value: "week" },
          { label: "This Month", value: "month" },
          { label: "This Year", value: "year" },
        ].map((btn) => (
          <Button
            key={btn.value}
            onClick={() => handlePeriodChange(btn.value)}
            variant={period === btn.value ? "default" : "outline"}
            className="flex-grow sm:flex-grow-0"
          >
            {btn.label}
          </Button>
        ))}
      </div>
      <div className="bg-[#7485BF] text-white text-center p-4 text-xl sm:text-2xl md:text-3xl font-semibold mb-6">
        {ticketData?.totalSells && (
          <>
            Total Page Sales: Ksh {ticketData.totalSells?.toFixed(2) ?? "0.00"}
          </>
        )}
      </div>
      <DataTable
        columns={columns}
        data={tableData}
        pageCount={ticketData?.totalPages ?? 1}
        pageIndex={pageIndex}
        onPaginationChange={setPageIndex}
      />
    </div>
  );
}
