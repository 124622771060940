import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import {
  BookingOfficeData,
  BookingOfficeDataError,
} from "../utility_components/utility_functions/utilityFunctions";
import { usePostOfficeLoginMutation } from "../../api/busLineOfficeApi";
import { errorHandler } from "../../error/index";
import { useNavigate } from "react-router-dom";

export default function BookOfficePrompt({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [postOfficeData] = usePostOfficeLoginMutation();
  const [officeData, setOfficeData] = useState<BookingOfficeData>({
    companyName: "",
    officeName: "",
    password: "",
  });
  const [errors, setErrors] = useState<BookingOfficeDataError>({});
  const validateForm = () => {
    let newErrors: BookingOfficeDataError = {};

    if (!officeData.companyName.trim())
      newErrors.companyName = "Company name is required";
    if (!officeData.officeName.trim())
      newErrors.officeName = "Office name  is required";
    if (!officeData.password.trim())
      newErrors.password = "Password is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setOfficeData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const officeLoginData = {
        companyName: officeData.companyName,
        officeName: officeData.officeName,
        password: officeData.password,
      };
      const result = await postOfficeData({ officeLoginData });
      if ("data" in result) {
        const officeDataToStore = {
          token: result.data.token,
          officeName: result.data.officeName,
          officeId: result.data.officeId,
          companyId: result.data.companyId,
        };
        localStorage.setItem("officeData", JSON.stringify(officeDataToStore));
        navigate("/office/home", { replace: true });
        officeData.companyName = "";
        officeData.officeName = "";
        officeData.password = "";
        onClose();
      } else {
        onClose();
        return errorHandler(result.error);
      }
    }
  };
  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="w-[95vw] sm:w-[90vw] md:max-w-[650px] h-[95vh] sm:h-auto max-h-[95vh] flex flex-col overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader className="shrink-0">
            <DialogTitle className="text-xl sm:text-2xl">
              Add Booking Office Data
            </DialogTitle>
          </DialogHeader>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col flex-grow overflow-hidden"
          >
            <div className="flex-grow overflow-auto bg-customLightBlue p-4">
              <div className="space-y-4 min-w-[280px]">
                <div>
                  <Label
                    htmlFor="name"
                    className="text-base sm:text-lg block mb-1"
                  >
                    Company Name
                  </Label>
                  <Input
                    id="name"
                    placeholder="Office Name"
                    className="w-full"
                    name="companyName"
                    value={officeData.companyName}
                    onChange={handleChange}
                  />
                  {errors.companyName && (
                    <span className="text-red-500 text-xs sm:text-sm block mt-1">
                      {errors.companyName}
                    </span>
                  )}
                </div>
                <div>
                  <Label
                    htmlFor="address"
                    className="text-base sm:text-lg block mb-1"
                  >
                    Office Name
                  </Label>
                  <Input
                    id="address"
                    placeholder="Office Address"
                    className="w-full"
                    name="officeName"
                    value={officeData.officeName}
                    onChange={handleChange}
                  />
                  {errors.officeName && (
                    <span className="text-red-500 text-xs sm:text-sm block mt-1">
                      {errors.officeName}
                    </span>
                  )}
                </div>
                <div>
                  <Label
                    htmlFor="city"
                    className="text-base sm:text-lg block mb-1"
                  >
                    Booking Office Password
                  </Label>
                  <Input
                    id="bPassword"
                    placeholder="Booking office Password"
                    className="w-full"
                    type="password"
                    name="password"
                    value={officeData.password}
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <span className="text-red-500 text-xs sm:text-sm block mt-1">
                      {errors.password}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <DialogFooter className="mt-4 sm:mt-6 px-4 shrink-0">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-base sm:text-lg"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
