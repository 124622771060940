// useAuth.ts
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../slices/types";
import { setUserAuth } from "../slices/userSlice";

export function useNormalUserAuth() {
  const dispatch = useDispatch();
  const { token, userId, userName, phoneNumber, role, exp, iat, email } =
    useSelector((state: RootState) => state.userAuth);

  useEffect(() => {
    try {
      const authToken = localStorage.getItem("userData");
      if (authToken) {
        const decodedToken = JSON.parse(authToken);
        dispatch(
          setUserAuth({
            token: decodedToken.token,
            exp: decodedToken.exp,
            iat: decodedToken.iat,
            phoneNumber: decodedToken.phoneNumber,
            role: decodedToken.role,
            userId: decodedToken.userId,
            userName: decodedToken.userName,
            email: decodedToken.email,
          })
        );
      }
    } catch (error) {
      console.error("Error parsing token:", error);
    }
  }, [dispatch]);

  return { token, userId, userName, phoneNumber, role, exp, iat, email };
}
