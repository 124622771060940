import React, { useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { useState } from "react";
import { usePostCompanyAddVehicleMutation } from "../../api/busLineApiData";
import { useDispatch } from "react-redux";
import { clearError } from "../../slices/errorSlice";
import { setSuccess } from "../../slices/successSlice";
import { setLoading } from "../../slices/loader";
import {
  FleetFormData,
  FleetFormErrors,
  OwnerAndVehicleProps,
} from "../utility_components/utility_functions/utilityFunctions";
import { useAuth } from "../../api/getCredentials";
import { useGetCompanyFleetQuery } from "../../api/busLineApiData";
import { errorHandler } from "../../error/index";
import { validateKenyanPhoneNumber } from "../utility_components/utility_functions/utilityFunctions";

const OwnerAndVehicle: React.FC<OwnerAndVehicleProps> = ({
  isOpen,
  onClose,
}) => {
  const [postVehicle, { isLoading }] = usePostCompanyAddVehicleMutation();
  const [errors, setErrors] = useState<FleetFormErrors>({});
  const { token, companyId } = useAuth();
  const { refetch } = useGetCompanyFleetQuery(companyId, {
    skip: !token || !companyId,
  });

  const dispatch = useDispatch();
  const [fleetFormData, setFleetFormData] = useState<FleetFormData>({
    vehicleRegistrationNumber: "",
    vehicleModel: "",
    vehicleCapacity: 0,
    ownerName: "",
    ownerEmail: "",
    ownerPhone: "",
    driverEmail: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFleetForm()) {
        const result = await postVehicle({
          addVehicleData: fleetFormData,
          companyId
        });
        if ("data" in result) {
          console.log("Added vehicle successfully", result);
          fleetFormData.driverEmail = "";
          fleetFormData.ownerEmail = "";
          fleetFormData.ownerName = "";
          fleetFormData.ownerPhone = "";
          fleetFormData.vehicleCapacity = 0;
          fleetFormData.vehicleModel = "";
          fleetFormData.vehicleRegistrationNumber = "";
          dispatch(setLoading(isLoading));
          dispatch(setSuccess("Vehicle added successfully"));
          refetch();
          onClose();
        } else {
          onClose();
          return errorHandler(result.error);
        }
      
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFleetFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateFleetForm = () => {
    let newErrors: FleetFormErrors = {};

    if (!fleetFormData.vehicleRegistrationNumber.trim())
      newErrors.vehicleRegistrationNumber = "Vehicle Reg Number is Required";
    if (!fleetFormData.vehicleModel.trim())
      newErrors.vehicleModel = "Vehicle model is Required";
    if (!fleetFormData.vehicleCapacity)
      newErrors.vehicleCapacity = "Vehicle capacity is required";
    if (!fleetFormData.ownerEmail.trim())
      newErrors.ownerEmail = "Owner email is required";
    else if (!/\S+@\S+\.\S+/.test(fleetFormData.ownerEmail))
      newErrors.ownerEmail = "Email is invalid";
    if (!fleetFormData.ownerName)
      newErrors.ownerName = "Owner name is required";
    if (!fleetFormData.driverEmail)
      newErrors.driverEmail = "Driver email is required";
    if (!fleetFormData.ownerPhone.trim())
      newErrors.ownerPhone = "Owner phone number is required";
    else if (!/^\d+$/.test(fleetFormData.ownerPhone))
      newErrors.ownerPhone = "Phone number must contain only digits";
    else if (!validateKenyanPhoneNumber(fleetFormData.ownerPhone))
      newErrors.ownerPhone = "Invalid Phone number. Use the 07.. format";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent
          className="sm:max-w-[650px] w-full flex flex-col max-h-[90vh]"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Add Owner and Vehicle Information
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="bg-customLightBlue">
            <div className="flex flex-col flex-grow overflow-hidden bg-customLightBlue">
              <div>
                <Label htmlFor="vehicleRegNo" className="text-lg">
                  Vehicle Registration Number
                </Label>
                <Input
                  id="vehcRegNo"
                  placeholder=" Vehicle Registration Number"
                  className="mt-2"
                  value={fleetFormData.vehicleRegistrationNumber}
                  name="vehicleRegistrationNumber"
                  onChange={handleChange}
                />
                {errors.vehicleRegistrationNumber && (
                  <span className="text-red-500 text-sm">
                    {errors.vehicleRegistrationNumber}
                  </span>
                )}
              </div>
              <div>
                <Label htmlFor="vehicleModel" className="text-lg">
                  Vehicle Model
                </Label>
                <Input
                  id="vehcModel"
                  placeholder="Vehicle Model"
                  className="mt-2"
                  value={fleetFormData.vehicleModel}
                  name="vehicleModel"
                  onChange={handleChange}
                />
                {errors.vehicleModel && (
                  <span className="text-red-500 text-sm">
                    {errors.vehicleModel}
                  </span>
                )}
              </div>
              <div>
                <Label htmlFor="vehicleCapacity" className="text-lg">
                  Vehicle Capacity
                </Label>
                <Input
                  id="vehcCapacity"
                  placeholder="Vehicle Capacity"
                  className="mt-2"
                  type="number"
                  name="vehicleCapacity"
                  value={fleetFormData.vehicleCapacity}
                  onChange={handleChange}
                />
                {errors.vehicleCapacity && (
                  <span className="text-red-500 text-sm">
                    {errors.vehicleCapacity}
                  </span>
                )}
              </div>
              <div>
                <Label htmlFor="ownerEmail" className="text-lg">
                  Owner Email
                </Label>
                <Input
                  id="ownerEmail"
                  placeholder="Owner Email"
                  className="mt-2"
                  name="ownerEmail"
                  value={fleetFormData.ownerEmail}
                  onChange={handleChange}
                />
                {errors.ownerEmail && (
                  <span className="text-red-500 text-sm">
                    {errors.ownerEmail}
                  </span>
                )}
              </div>
              <div>
                <Label htmlFor="ownerName" className="text-lg">
                  Owner Name
                </Label>
                <Input
                  id="ownerName"
                  placeholder="Owner Name"
                  className="mt-2"
                  name="ownerName"
                  value={fleetFormData.ownerName}
                  onChange={handleChange}
                />
                {errors.ownerName && (
                  <span className="text-red-500 text-sm">
                    {errors.ownerName}
                  </span>
                )}
              </div>
              <div>
                <Label htmlFor="ownerPhone" className="text-lg">
                  Owner Phone
                </Label>
                <Input
                  id="ownerPhone"
                  placeholder="Owner Number"
                  type="number"
                  className="mt-2"
                  name="ownerPhone"
                  value={fleetFormData.ownerPhone}
                  onChange={handleChange}
                />
                {errors.ownerPhone && (
                  <span className="text-red-500 text-sm">
                    {errors.ownerPhone}
                  </span>
                )}
              </div>
              <div>
                <Label htmlFor="driverEmail" className="text-lg">
                  Driver Email
                </Label>
                <Input
                  id="driverEmail"
                  placeholder="Driver Email"
                  className="mt-2"
                  name="driverEmail"
                  value={fleetFormData.driverEmail}
                  onChange={handleChange}
                />
                {errors.driverEmail && (
                  <span className="text-red-500 text-sm">
                    {errors.driverEmail}
                  </span>
                )}
              </div>
            </div>
            <DialogFooter className="mt-6">
              <Button
                type="submit"
                className="w-full bg-customDarkBlue text-white py-2 text-lg"
              >
                Done
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default OwnerAndVehicle;
