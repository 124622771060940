import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showSuccess: false,
    successMessage: '',
  };
  
  const successSlice = createSlice({
    name: 'success',
    initialState,
    reducers: {
      setSuccess: (state, action) => {
        state.showSuccess = true;
        state.successMessage = action.payload;
      },
      clearSuccess: (state) => {
        state.showSuccess = false;
        state.successMessage = '';
      },
    },
  });
  
  export const { setSuccess, clearSuccess } = successSlice.actions;
  export default successSlice.reducer;