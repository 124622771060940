import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Card, CardContent } from "../../components/ui/card";
import { useNavigate } from "react-router-dom";
import logo from "../../image/logo.png";
import bus from "../../image/bus.png";
import car from "../../image/car.png";
import shape from "../../image/shape.png";
import { PersonStanding, HouseIcon, WalletIcon } from "lucide-react";
import BookOfficePrompt from "../BookingOffice/BookOfficePrompt";
import OwnerPrompt from "../Owner/OwnerPrompt";

export default function Home() {
  const navigate = useNavigate();
  const [isBookingOfficeDialogOpen, setBookingOfficeOpen] = useState(false);
  const openDialog = () => setBookingOfficeOpen(true);
  const closeDialog = () => setBookingOfficeOpen(false);
  const [isOwnerDialogOpen, setOwnerOpen] = useState(false);
  const openOwnerDialog = () => setOwnerOpen(true);
  const closeOwnerDialog = () => setOwnerOpen(false);
  return (
    <div className="flex flex-col min-h-screen relative">
      <div className="absolute inset-0 w-full h-[120vh]">
        <div className="relative w-full h-full overflow-hidden">
          <img
            src={shape}
            alt="Overlap 1"
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>
      </div>

      <div className="relative z-10">
        <header className="bg-background/80 px-4 py-3 md:px-6 md:py-4">
          <div className="container flex items-center justify-between">
            <div className="flex items-center gap-2">
              <img src={logo} alt="BusLine logo" className="h-10 w-auto" />
              <span className="text-lg font-bold">BusLine</span>
            </div>

            <nav className="hidden md:flex items-center justify-center flex-grow">
              <div className="flex justify-between w-full max-w-md">
                <Link to="#" className="text-lg font-medium">
                  Sacco
                </Link>
                <Link to="#" className="text-lg font-medium">
                  About
                </Link>
                <Link to="#" className="text-lg font-medium">
                  Help
                </Link>
                <Link to="#" className="text-lg font-medium">
                  Contacts
                </Link>
              </div>
            </nav>
            <div className="hidden md:flex items-center gap-4">
              <Button
                style={{ backgroundColor: "#7190FF" }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Company Login
              </Button>
              <Button
                style={{ backgroundColor: "#7190FF" }}
                onClick={() => {
                  navigate("/sign-up");
                }}
              >
                Company Sign Up
              </Button>
            </div>
          </div>
        </header>

        <main className="flex-1">
          <section className="bg-background/80 py-12 md:py-24 lg:py-32">
            <div className="container grid grid-cols-1 md:grid-cols-2 items-center gap-8">
              <div className="space-y-4">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl">
                  <span className="block text-yellow-500">BusLine</span>
                  <span className="block text-yellow-500">Digital</span>
                  <span className="block text-customDarkBlue">
                    BackOffice System
                  </span>
                  <span className="block text-black">for Public transport</span>
                </h1>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div className="flex items-center justify-center">
                  <img
                    src={bus}
                    width="300"
                    height="300"
                    alt="Bus"
                    className="w-full max-w-[300px] h-auto rounded-lg object-contain"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={car}
                    width="300"
                    height="180"
                    alt="Car"
                    className="w-full max-w-[300px] h-auto rounded-lg object-contain"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="bg-muted py-12 md:py-24 lg:py-32">
            <div className="container text-center space-y-4">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                ONE CLICK Automatic BackOffice
              </h2>
              <p className="max-w-[700px] mx-auto text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Turn long frustrating tiresome hours of processing records,
                auditing, reporting, employee & fleet management to just ONE
                BUTTON CLICK.
              </p>
            </div>
          </section>

          <section className="bg-background py-12 md:py-24 lg:py-32">
            <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <Card className="bg-[#EEF1FE] text-center">
                <CardContent className="flex flex-col items-center justify-center gap-4 p-6">
                  <div className="bg-[#7190FF] rounded-full flex items-center justify-center aspect-square w-16 md:w-20">
                    <TicketIcon className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-lg font-semibold">Business</h3>
                  <Button
                    className="w-full"
                    style={{ backgroundColor: "#7190FF" }}
                    onClick={() => {
                      navigate("/sign-up");
                    }}
                  >
                    Sign Up
                  </Button>
                </CardContent>
              </Card>
              <Card className="bg-[#EEF1FE] text-center">
                <CardContent className="flex flex-col items-center justify-center gap-4 p-6">
                  <div className="bg-[#7190FF] rounded-full flex items-center justify-center aspect-square w-16 md:w-20">
                    <WalletIcon className="w-8 h-8 text-white" />
                  </div>
                  <h3 className="text-lg font-semibold">Driver</h3>
                  <Button
                    style={{ backgroundColor: "#7190FF" }}
                    className="w-full"
                    onClick={() => {
                      navigate("/user-sign-up");
                    }}
                  >
                    Sign Up
                  </Button>
                </CardContent>
              </Card>
              <Card className="bg-[#EEF1FE] text-center">
                <CardContent className="flex flex-col items-center justify-center gap-4 p-6">
                  <div className="bg-[#7190FF] rounded-full flex items-center justify-center aspect-square w-16 md:w-20">
                    <PersonStanding size={40} />
                  </div>
                  <h3 className="text-lg font-semibold">Passenger</h3>
                  <Button
                    style={{ backgroundColor: "#7190FF" }}
                    className="w-full"
                    onClick={() => {
                      navigate("/user-sign-up");
                    }}
                  >
                    Sign Up
                  </Button>
                </CardContent>
              </Card>
              <Card className="bg-[#EEF1FE] text-center">
                <CardContent className="flex flex-col items-center justify-center gap-4 p-6">
                  <div className="bg-[#7190FF] rounded-full flex items-center justify-center aspect-square w-16 md:w-20">
                    <PersonStanding size={40} />
                  </div>
                  <h3 className="text-lg font-semibold">Owner</h3>
                  <Button
                    className="w-full"
                    style={{ backgroundColor: "#7190FF" }}
                    onClick={() => openOwnerDialog()}
                  >
                    Sign Up
                  </Button>
                </CardContent>
              </Card>
              <Card className="bg-[#EEF1FE] text-center">
                <CardContent className="flex flex-col items-center justify-center gap-4 p-6">
                  <div className="bg-[#7190FF] rounded-full flex items-center justify-center aspect-square w-16 md:w-20">
                    <HouseIcon size={40} />
                  </div>
                  <h3 className="text-lg font-semibold">Booking office</h3>
                  <Button
                    className="w-full"
                    style={{ backgroundColor: "#7190FF" }}
                    onClick={() => openDialog()}
                  >
                    Access
                  </Button>
                </CardContent>
              </Card>
            </div>
          </section>
        </main>
      </div>

      <footer className="bg-muted py-6 text-center text-muted-foreground">
        <div className="container">
          &copy; 2024 Busline. All rights reserved.
        </div>
      </footer>
      <BookOfficePrompt
        isOpen={isBookingOfficeDialogOpen}
        onClose={() => closeDialog()}
      />
      <OwnerPrompt
        isOpen={isOwnerDialogOpen}
        onClose={() => closeOwnerDialog()}
      />
    </div>
  );
}

function TicketIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
      <path d="M13 5v2" />
      <path d="M13 17v2" />
      <path d="M13 11v2" />
    </svg>
  );
}
