import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import AddBookingOffice from "./AddBookingOffice";
import { useAuth } from "../../api/getCredentials";
import { errorHandler } from "../../error";
import { useLazyGenerateOfficePasswordQuery } from "../../api/busLineApiData";
import PasswordDisplay from "./PasswordDisplay";
import CompanyOfficeTableData from "./CompanyOffices/company_offices_page";

export default function CompanyListOffice() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPassDialogOpen, setIsPassDialogOpen] = useState(false);
  const { companyId,} = useAuth();
  const [passwordData, setPasswordData] = useState(null);
  const [
    generatePassword,
    {error, isError: generatePasswordError, data, isSuccess },
  ] = useLazyGenerateOfficePasswordQuery();
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const closePassDialog = () => {
    setIsPassDialogOpen(false);
    setPasswordData(null);
  };
  const handleGeneratePassword = (officeId) => {
    setPasswordData(null);
    generatePassword({
      companyId,
      officeId: officeId,
    });
  };

  useEffect(() => {
    if (generatePasswordError) {
      errorHandler(error);
    }

    if (isSuccess && data) {
      setPasswordData(data);
      setIsPassDialogOpen(true);
    }
  }, [data, error, generatePasswordError, isSuccess]);

  return (
    <div className="w-full max-w-full mx-auto px-4 sm:px-6 lg:px-8">
      <Button
        className="bg-customDarkBlue text-white mx-auto mt-4 block w-full sm:w-auto"
        onClick={openDialog}
      >
        Add Booking Office
      </Button>
      <CompanyOfficeTableData openPasswordDialog={handleGeneratePassword} />

      <AddBookingOffice isOpen={isDialogOpen} onClose={closeDialog} />
      {isSuccess && data && passwordData && (
        <PasswordDisplay
          isOpen={isPassDialogOpen}
          message={passwordData.message}
          password={passwordData.plaintextPassword}
          note={passwordData.note}
          onClose={closePassDialog}
        />
      )}
    </div>
  );
}
