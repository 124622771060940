import React from "react";
import CompanyTicketsReportTable from "./reports/company_tickets_reports_page";

export default function Reports() {
  return (
    <>
      <CompanyTicketsReportTable />
    </>
  );
}
